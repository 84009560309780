export const multiLingColumns = [
    {
        name: 'SR.NO.',
        sortable: true,
        maxWidth: '125px',
        selector: row => row.disbursementId
    },
    {
        name: 'Case Name',
        sortable: true,
        minWidth: '200px',
        selector: row => row.customer_name
    },
    {
        name: 'Loan Amount',
        sortable: true,
        minWidth: '200px',
        selector: row => row.loan_amount_bank
    },
    {
        name: 'Banker',
        sortable: true,
        minWidth: '100px',
        selector: row => row.bank_name
    },
    {
        name: 'Prouduct',
        sortable: true,
        minWidth: '150px',
        selector: row => row.product
    },

    {
        name: 'Gross Payout',
        sortable: true,
        minWidth: '200px',
        selector: row => row.gross_payout_bank
    },
    {
        name: 'Case Status',
        sortable: true,
        minWidth: '150px',
        selector: row => row.billingStatus,
    }
]
