import { Provider } from 'react-redux';
import Navigation from './services/navigation';
import store from './redux/store'
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { useEffect } from 'react';
import AxiosWrapper from './services/apiConfig';

let persistor = persistStore(store);

store.subscribe(() => console.log(store.getState()))

function App() {

  useEffect(() => {
    new AxiosWrapper()
  }, [])

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Navigation />
      </PersistGate>
    </Provider>
  );
}

export default App;
