import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import OrendaLogo from '../../../assets/images/orenda-logo.png'
import Orenda from '../../../assets/images/orenda.png'

const Sidebar = () => {
    const [open, setOpen] = useState(true)
    const openSildeBar = () => {
        setOpen(!open)
    }
    return (
        <>  <nav className={`sidebar ${!open ? 'close' : ''}`}>
            <header>
                <div className="image-text">
                    {
                        !open ? <span className="image">
                            <img src={OrendaLogo} alt="logo" />
                        </span> : ""
                    }

                    <div className="text logo-text">
                        <img src={Orenda} alt="logo" />
                    </div>
                </div>


                <svg className='toggle' onClick={openSildeBar} xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="15" height="15" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path fill="currentColor" d="m6 15l5-5l-5-5l1-2l7 7l-7 7z" /></svg>
            </header>

            <div className="menu-bar">
                <div className="menu">

                    <ul className="menu-links">
                        <li className="nav-link">
                            <NavLink to={'/home'} activeClassName='active'>
                                <svg className='icon' xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32"><path fill="currentColor" d="M4 20v2h4.586L2 28.586L3.414 30L10 23.414V28h2v-8H4zm20 1h2v5h-2zm-4-5h2v10h-2zm-4 2h2v8h-2z" /><path fill="currentColor" d="M28 2H4a2.002 2.002 0 0 0-2 2v12h2v-3h24.001l.001 15H16v2h12a2.003 2.003 0 0 0 2-2V4a2.002 2.002 0 0 0-2-2Zm-16 9H4V4h8Zm2 0V4h14v7Z" /></svg>
                                <span className="text nav-text">Dashboard</span>
                            </NavLink>
                        </li>

                        <li className="nav-link">
                            <NavLink to={'/user-reffered'} activeClassName='active'>
                                <svg className='icon' xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 256 256"><path fill="currentColor" d="M129.2 156.9a64 64 0 1 0-82.4 0a100.1 100.1 0 0 0-40.6 33.6a12 12 0 0 0 2.9 16.7a11.8 11.8 0 0 0 6.9 2.2a11.9 11.9 0 0 0 9.8-5.1a76 76 0 0 1 124.4 0a12 12 0 1 0 19.6-13.8a100.1 100.1 0 0 0-40.6-33.6ZM48 108a40 40 0 1 1 40 40a40 40 0 0 1-40-40Zm200.4 99.2a11.8 11.8 0 0 1-6.9 2.2a12.1 12.1 0 0 1-9.8-5.1a76.2 76.2 0 0 0-62.2-32.3a12 12 0 0 1 0-24a40 40 0 0 0 0-80a39.1 39.1 0 0 0-10.8 1.5a12 12 0 1 1-6.5-23.1a62.8 62.8 0 0 1 17.3-2.4a63.9 63.9 0 0 1 41.2 112.9a100.1 100.1 0 0 1 40.6 33.6a11.9 11.9 0 0 1-2.9 16.7Z" /></svg>
                                <span className="text nav-text">User Reffered</span>
                            </NavLink>
                        </li>

                        <li className="nav-link">
                            <NavLink to={'/kyc'} activeClassName='active'>
                                <svg className='icon' xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path fill="currentColor" d="M858.5 763.6a374 374 0 0 0-80.6-119.5a375.63 375.63 0 0 0-119.5-80.6c-.4-.2-.8-.3-1.2-.5C719.5 518 760 444.7 760 362c0-137-111-248-248-248S264 225 264 362c0 82.7 40.5 156 102.8 201.1c-.4.2-.8.3-1.2.5c-44.8 18.9-85 46-119.5 80.6a375.63 375.63 0 0 0-80.6 119.5A371.7 371.7 0 0 0 136 901.8a8 8 0 0 0 8 8.2h60c4.4 0 7.9-3.5 8-7.8c2-77.2 33-149.5 87.8-204.3c56.7-56.7 132-87.9 212.2-87.9s155.5 31.2 212.2 87.9C779 752.7 810 825 812 902.2c.1 4.4 3.6 7.8 8 7.8h60a8 8 0 0 0 8-8.2c-1-47.8-10.9-94.3-29.5-138.2zM512 534c-45.9 0-89.1-17.9-121.6-50.4S340 407.9 340 362c0-45.9 17.9-89.1 50.4-121.6S466.1 190 512 190s89.1 17.9 121.6 50.4S684 316.1 684 362c0 45.9-17.9 89.1-50.4 121.6S557.9 534 512 534z" /></svg>
                                <span className="text nav-text">KYC Status</span>
                            </NavLink>
                        </li>

                        <li className="nav-link">
                            <NavLink to={'/login-code'} activeClassName='active'>
                                <svg className='icon' xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"><path d="M4 21.4V2.6a.6.6 0 0 1 .6-.6h11.652a.6.6 0 0 1 .424.176l3.148 3.148A.6.6 0 0 1 20 5.75V21.4a.6.6 0 0 1-.6.6H4.6a.6.6 0 0 1-.6-.6Z" /><path fill="currentColor" d="M16 5.4V2.354a.354.354 0 0 1 .604-.25l3.292 3.292a.353.353 0 0 1-.25.604H16.6a.6.6 0 0 1-.6-.6Z" /><path d="M8 10h8m-8 8h8m-8-4h4" /></g></svg>
                                <span className="text nav-text">Login Code List</span>
                            </NavLink>
                        </li>

                        <li className="nav-link">
                            <NavLink to={'/payout-gird'} activeClassName="active">
                                <svg className='icon' xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M20 3H5C3.346 3 2 4.346 2 6v12c0 1.654 1.346 3 3 3h15c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zM5 19c-.552 0-1-.449-1-1V6c0-.551.448-1 1-1h15v3h-6c-1.103 0-2 .897-2 2v4c0 1.103.897 2 2 2h6.001v3H5zm15-9v4h-6v-4h6z" /></svg>
                                <span className="text nav-text">Payout Gird</span>
                            </NavLink>
                        </li>

                        <li className="nav-link">
                            <NavLink to={'/sm-list'} activeClassName="active">
                                <svg className='icon' xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path fill="currentColor" d="M9.072 9.444a.5.5 0 1 0 .99.145l.086-.587h1.24l-.066.442a.5.5 0 1 0 .99.145l.086-.587H13a.5.5 0 0 0 0-1h-.456l.22-1.5h.736a.5.5 0 0 0 0-1h-.59l.137-.93a.5.5 0 0 0-.99-.144L11.9 5.502h-1.24l.137-.93a.5.5 0 0 0-.99-.144L9.65 5.502H9a.5.5 0 0 0 0 1h.503l-.22 1.5H8.5a.5.5 0 0 0 0 1h.637l-.065.442Zm2.681-2.942l-.22 1.5h-1.239l.22-1.5h1.24ZM6 2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H6Zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1Z" /></svg>
                                <span className="text nav-text">SM List</span>
                            </NavLink>
                        </li>

                        <li className="nav-link">
                            <NavLink to={'/connector-list'} activeClassName="active">
                                <svg className='icon' xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path fill="currentColor" d="M128 192v640h768V320H485.76L357.504 192H128zm-32-64h287.872l128.384 128H928a32 32 0 0 1 32 32v576a32 32 0 0 1-32 32H96a32 32 0 0 1-32-32V160a32 32 0 0 1 32-32zm256 416h320v64H352v-64z" /></svg>
                                <span className="text nav-text">Connecter List</span>
                            </NavLink>
                        </li>

                        <li className="nav-link">
                            <NavLink to={'/disbursement'} activeClassName='active'>
                                <svg className='icon' xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><g fill="currentColor"><path d="M4.5 12a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-1zm3 0a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1zm3 0a.5.5 0 0 1-.5-.5v-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-.5.5h-1z" /><path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" /></g></svg>
                                <span className="text nav-text">Disbursement Data</span>
                            </NavLink>
                        </li>

                        <li className="nav-link">
                            <NavLink to={'pending-review'} activeClassName='active'>
                                <svg className='icon' xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10s10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8s8 3.589 8 8s-3.589 8-8 8z" /><path fill="currentColor" d="M13 7h-2v5.414l3.293 3.293l1.414-1.414L13 11.586z" /></svg>
                                <span className="text nav-text">Pending Review</span>
                            </NavLink>
                        </li>

                    </ul>
                </div>

                <div className="bottom-content">
                    <span className={`title ${!open ? 'close' : ''}`}>RELATIONSHIP MANAGER</span>
                    <div className="userInfo">
                        <div className="img">
                            <img src="https://www.w3cplus.com/sites/default/files/blogs/2020/2004/css-circle-image-2.jpg" alt="" />
                        </div>
                        <div className={`info ${!open ? 'close' : ''}`}>
                            <h5>Sushma Patel</h5>
                            <p>sushma@gmail.com</p>
                            <p>513-872-8157</p>
                        </div>
                    </div>
                </div>
            </div>

        </nav>

        </>
    )
}

export default Sidebar