import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import Header from '../../components/structure/Header'
import Sidebar from '../../components/structure/Sidebar'
import { Card, CardHeader, CardTitle, CardBody, Row, Col, Input, Button, Form } from 'reactstrap'
import { kycUpload } from '../../redux/payment'

const KYC = () => {
    const dispatch = useDispatch()
    const [file, setFile] = useState({
        fileName: '',
        data: ''
    })
    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useForm()

    const onChange = e => {
        const reader = new FileReader(),
            files = e.target.files
        reader.onload = function () {
            setFile({
                fileName: files[0].name,
                data: reader.result
            })
        }
        reader.readAsDataURL(files[0])
    }

    const onSubmit = data => {
        const object = {
            data,
            files: file
        }
        dispatch(kycUpload(object)).then((res) => {
            console.log(res)
        }).catch((err) => {
            console.log(err)
        })

    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Sidebar />
            <div className="home">
                <Header />
                <div className="container-dashboard">
                    <div className="mt-4">
                        <Card className='card-kyc'>
                            <CardHeader className='kyc-card'>
                                <CardTitle tag='h4'>KYC</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Row className='mt-4 mb-4'>
                                    <Col md='4' sm='12'>
                                        <div className="input-group-merge mb-2 input-group">
                                            <Input type='file' id='inputFile' onChange={onChange} name='fileInput' placeholder='KYC' />
                                            <span className="cursor-pointer input-group-text"><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"><rect width="18" height="18" x="3" y="3" rx="4" /><path d="m9 12l2.25 2L15 10" /></g></svg></span>
                                        </div>
                                    </Col>
                                    <Col md='4' sm='12'>
                                        <div className="input-group-merge mb-2 input-group">
                                            <Controller
                                                id='cancelledCheque'
                                                name='cancelledCheque'
                                                defaultValue=''
                                                control={control}
                                                render={({ field }) => <Input placeholder='Cancelled Cheque' invalid={errors.cancelledCheque && true} {...field} />}
                                            />
                                            <span className="cursor-pointer input-group-text"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg></span>
                                        </div>
                                    </Col>
                                    <Col md='4' sm='12'>
                                        <div className="input-group-merge mb-2 input-group">
                                            <Controller
                                                id='gst'
                                                name='gst'
                                                defaultValue=''
                                                control={control}
                                                render={({ field }) => <Input placeholder='GST Certificate(Optional)' invalid={errors.gst && true} {...field} />}
                                            />
                                            <span className="cursor-pointer input-group-text"><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1408 1408"><path fill="currentColor" d="M1152 672v64q0 14-9 23t-23 9H768v352q0 14-9 23t-23 9h-64q-14 0-23-9t-9-23V768H288q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h352V288q0-14 9-23t23-9h64q14 0 23 9t9 23v352h352q14 0 23 9t9 23zm128 448V288q0-66-47-113t-113-47H288q-66 0-113 47t-47 113v832q0 66 47 113t113 47h832q66 0 113-47t47-113zm128-832v832q0 119-84.5 203.5T1120 1408H288q-119 0-203.5-84.5T0 1120V288Q0 169 84.5 84.5T288 0h832q119 0 203.5 84.5T1408 288z" /></svg></span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md='2' sm='12' className='mb-3'>
                                        <div className="demo-inline-spacing">
                                            <Button color='info' type='submit'>Upload</Button>
                                        </div>
                                    </Col>
                                    <Col md='2' sm='12' className='mb-3'>
                                        <div className="demo-inline-spacing">
                                            <Button color='secondary'>Cancel</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        </Form>
    )
}

export default KYC