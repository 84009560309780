export const multiLingColumn = [
    {
        name: 'SR.NO.',
        sortable: true,
        maxWidth: '125px',
        selector: row => row.id
    },
    {
        name: 'Name',
        sortable: true,
        minWidth: '200px',
        selector: row => row.name
    },
    {
        name: 'City',
        sortable: true,
        minWidth: '200px',
        selector: row => row.loan_amount ? row.loan_amount : '4000'
    },
    {
        name: 'State',
        sortable: true,
        minWidth: '100px',
        selector: row => row.banker ? row.banker : 'HDFC Bank'
    },
    {
        name: 'Contact No.',
        sortable: true,
        minWidth: '150px',
        selector: row => row.prouduct ? row.prouduct : 'Bussines Loan'
    },

    {
        name: 'Total Billing',
        sortable: true,
        minWidth: '200px',
        selector: row => row.gross_payout ? row.gross_payout : '2.3%'
    }

]
