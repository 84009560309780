import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import Swal from 'sweetalert2'
import { BASE_URL } from '../../constants'
import { login } from '../../redux/user'
import Input from '../Input'
// ** Third Party Components
import { toast, Slide } from 'react-toastify'
import { SuccessToast } from '../elements/Toastify'

function SignIn({ setVisibleComponent }) {
    const dispatch = useDispatch()
    const history = useHistory();

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [isErrorVisible, setIsErrorVisible] = useState(false)

    const [errorMessage, setErrorMessage] = useState({
        email: 'Please enter valid email',
        password: 'Please should have following'
    })

    useEffect(() => {
        const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        if (email.length && regex.test(email))
            setErrorMessage({ ...errorMessage, email: '' })
        else
            setErrorMessage({ ...errorMessage, email: 'Please enter valid email' })
    }, [email])

    useEffect(() => {
        // const regex = /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/
        if (password.length)
            setErrorMessage({ ...errorMessage, password: '' })
        else
            setErrorMessage({ ...errorMessage, password: 'Please enter valid password' })
    }, [password])

    const signIn = async () => {
        setIsErrorVisible(true)
        if (errorMessage.email || errorMessage.password) return

        try {
            const response = await axios.post(
                `${BASE_URL}/user/login`,
                {
                    "idOrEmail": email,
                    "password": password
                }
            )
            dispatch(login(response.data.result))
            console.log(`Welcome, ${response.data.result.name}`)
            localStorage.setItem("token", response.data.result.token.access_token)
            history.push('/home')
            toast.success(<SuccessToast title={`Welcome, ${response.data.result.name}`} text={`You have successfully logged!`} />, { icon: false, transition: Slide, hideProgressBar: true })
        }
        catch (error) {
            console.log(error);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Email or Password Incorrect',
            })
        }
    }

    return (
        <div className="form">
            <div className="headers">
                <div className="title">Sign in</div>
                <div><a href="" className="close-modal">&times;</a></div>
            </div>
            <hr />
            <Input
                id="email"
                type="email"
                placeholder='Email'
                value={email}
                onChange={setEmail}
                icon={<img src={require("./../../assets/images/messageemail.png")} alt="" className="input-icon" />} />
            {isErrorVisible && <p className='error-text-msg'>{errorMessage.email}</p>}
            <Input
                id="password"
                type="password"
                placeholder='password'
                value={password}
                onChange={setPassword}
                icon={<img src={require("./../../assets/images/password-password.png")} alt="" className="input-icon" />} />
            {isErrorVisible && <p className='error-text-msg'>{errorMessage.password}</p>}

            <div className="form-details">
                <div className='d-flex align-item-center'>
                    <input type="checkbox" className="=" name="Remember" value="Remember me" />
                    <label htmlFor="Remember" className="sub-text ml-4">Remember me</label><br />
                </div>
                <div>
                    <a onClick={() => setVisibleComponent("ForgotPassword")} className="sub-text">Forgot Password?</a>
                </div>
            </div>

            <button onClick={(e) => {
                e.preventDefault()
                signIn()
            }} className="submit">Sign in</button>
            <div className="form-footer">
                <div className="sub-text">Don't have an account? <a onClick={() => setVisibleComponent("SignUp")} className="sign-up"><b> Sign Up</b></a></div>
            </div>
        </div>
    )
}

export default SignIn