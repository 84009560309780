import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { multiLingColumns } from './column'
import { fetchUsers, getAllPayments } from '../../../redux/payment'
import Table from '../../../components/elements/DataTable'

const PaymentCompleted = () => {
    const [currentPage, setCurrentPage] = useState(1)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchUsers({
            allowPagination: false
        }))
    }, [dispatch])

    const allUsers = useSelector(getAllPayments)

    // ** Function to handle filter
    const handlePagination = page => {
        console.log(Number(page))
        setCurrentPage(Number(page))
    }

    // ** Pagination Next Component
    const Next = (page) => {
        if (currentPage !== page) {
            setCurrentPage(Number(currentPage) + 1)
        }
    }

    return (
        <Table columns={multiLingColumns} currentPage={currentPage} data={Array.isArray(allUsers) ? allUsers : []} handlePagination={handlePagination} Next={Next} />
    )
}

export default PaymentCompleted