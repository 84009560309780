import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import { multiLingColumns } from './column'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import Select from 'react-select'
import Table from '../../components/elements/DataTable'
import Sidebar from '../../components/structure/Sidebar'
import Header from '../../components/structure/Header'
import { Card, CardHeader, CardTitle, CardBody, Row, Col, Input, Button, Form, Label, ModalBody, ModalFooter, FormFeedback } from 'reactstrap'
import { Briefcase, Mail, MapPin, Phone, PlusSquare, User } from 'react-feather'
import Modals from '../../components/elements/Modal'
import { addConnectorData, fetachConnectorList, getAllConnectorListSelector } from '../../redux/connector'
import classnames from 'classnames'
import { toast, Slide } from 'react-toastify'
import { ErrorToast, SuccessToast } from '../../components/elements/Toastify'

const colourOptions = [
    { value: 'a', label: 'A' },
    { value: 'b', label: 'B' },
    { value: 'c', label: 'C' },
    { value: 'd', label: 'D' },
    { value: 'e', label: 'E' }
]


const ConnectorList = () => {
    const [currentPage, setCurrentPage] = useState(1)
    const [openModal, setOpenModal] = useState(false)
    const dispatch = useDispatch()

    const Schema = yup.object().shape({
        name: yup.string().required('Name is required'),
        firmName: yup.string().min(3).required('Firm Name is required'),
        email: yup.string().email().required('Email is required'),
        mobileNo: yup.string().required('Mobile No is required'),
        panNo: yup.string().required('Pan No is required'),
        city: yup.object().required('City is required'),
        state: yup.object().required('State is required'),
        poastalCode: yup.string().required('Postal Code is required'),
    })

    const {
        reset,
        control,
        handleSubmit,
        formState: { errors }
    } = useForm({ mode: 'onChange', resolver: yupResolver(Schema) })

    useEffect(() => {
        dispatch(fetachConnectorList({
            allowPagination: false
        }))
    }, [dispatch])

    const onSubmit = data => {
        console.log("data", data)
        const object = {
            name: data.name,
            email: data.email,
            contact: data.mobileNo,
            pin: data.poastalCode,
            pan_no: data.panNo,
            city: data.city.value,
            state: data.state.value
        }

        dispatch(addConnectorData(object)).then(res => {
            if (res.payload.status === 200) {
                toast.success(<SuccessToast title='Success!' text={res.payload.message} />, { icon: false, hideProgressBar: true, transition: Slide })
                dispatch(fetachConnectorList({
                    allowPagination: false
                }))
                reset()
                setOpenModal(false)
            }
            else {
                toast.error(<ErrorToast title='Error!' text={res.payload.message} />, { icon: false, hideProgressBar: true, transition: Slide })
            }
        }).catch(err => {
            toast.error(<ErrorToast title='Error!' text={err.message} />, { icon: false, hideProgressBar: true, transition: Slide })
        })

    }


    const allConnectorList = useSelector(getAllConnectorListSelector)
    // console.log('Connector List ' + allConnectorList + " - " + Array.isArray(getAllConnectorListSelector))
    // const data = allConnectorList ? allConnectorList : []

    // ** Function to handle filter
    const handlePagination = page => {
        console.log(Number(page))
        setCurrentPage(Number(page))
    }

    // ** Pagination Next Component
    const Next = (page) => {
        if (currentPage !== page) {
            setCurrentPage(Number(currentPage) + 1)
        }
    }

    return (
        <> <Sidebar />
            <div className="home">
                <Header />
                <div className="container-dashboard">
                    <div className="search">
                        <div className="field" id="searchform">
                            <input type="text" id="searchterm" placeholder="Search here..." />
                            <button type="button" id="search">Search</button>
                        </div>
                    </div>
                    <div className="mt-4">
                        <Card>
                            <CardHeader className='kyc-card'>
                                <CardTitle tag='h4'>Connector List</CardTitle>
                                <div className="actions">
                                    <div className="demo-inline-spacing">
                                        <Button color='flat-success' onClick={() => setOpenModal(!openModal)}> <span className='mr-4'><PlusSquare /></span> Add Connector</Button>
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody>

                                <Table columns={multiLingColumns} currentPage={currentPage} data={allConnectorList} handlePagination={handlePagination} Next={Next} />
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>

            {/* Add Connector Modal */}

            <Modals openModal={openModal} setOpenModal={setOpenModal} title='Add Connector Data' >
                <Form>
                    <ModalBody>
                        <Row>
                            <Col md='6' sm='12'>
                                <div className="input-group-merge mb-4 input-group">
                                    <Controller
                                        id='name'
                                        name='name'
                                        defaultValue=''
                                        control={control}
                                        render={({ field }) => <Input placeholder='Name' invalid={errors.name && true} {...field} />}
                                    />
                                    {errors.name ? <FormFeedback>{errors.name.message}</FormFeedback> : <span className="cursor-pointer input-group-text"><User /></span>}
                                </div>
                            </Col>
                            <Col md='6' sm='12'>
                                <div className="input-group-merge mb-4 input-group">
                                    <Controller
                                        id='firmName'
                                        name='firmName'
                                        defaultValue=''
                                        control={control}
                                        render={({ field }) => <Input placeholder='Firm Name' invalid={errors.firmName && true} {...field} />}
                                    />
                                    {errors.firmName ? <FormFeedback>{errors.firmName.message}</FormFeedback> : <span className="cursor-pointer input-group-text"><Briefcase /></span>}
                                </div>
                            </Col>
                            <Col md='6' sm='12'>
                                <div className="input-group-merge mb-4 input-group">
                                    <Controller
                                        id='email'
                                        name='email'
                                        defaultValue=''
                                        control={control}
                                        render={({ field }) => <Input placeholder='Email' invalid={errors.email && true} {...field} />}
                                    />
                                    {errors.email ? <FormFeedback>{errors.email.message}</FormFeedback> : <span className="cursor-pointer input-group-text"><Mail /></span>}
                                </div>
                            </Col>
                            <Col md='6' sm='12'>
                                <div className="input-group-merge mb-4 input-group">
                                    <Controller
                                        id='mobileNo'
                                        name='mobileNo'
                                        defaultValue=''
                                        control={control}
                                        render={({ field }) => <Input placeholder='Mobile No.' invalid={errors.mobileNo && true} {...field} />}
                                    />
                                    {errors.mobileNo ? <FormFeedback>{errors.mobileNo.message}</FormFeedback> : <span className="cursor-pointer input-group-text"><Phone /></span>}
                                </div>
                            </Col>
                            <Col md='6' sm='12'>
                                <div className="input-group-merge mb-4 input-group">
                                    <Controller
                                        id='panNo'
                                        name='panNo'
                                        defaultValue=''
                                        control={control}
                                        render={({ field }) => <Input placeholder='Pan No.' invalid={errors.panNo && true} {...field} />}
                                    />
                                    {errors.panNo ? <FormFeedback>{errors.panNo.message}</FormFeedback> : <span className="cursor-pointer input-group-text"><User /></span>}
                                </div>
                            </Col>
                            <Col md='6' sm='12' className='mb-4'>
                                <Controller
                                    id='state'
                                    control={control}
                                    name='state'
                                    render={({ field }) => (
                                        <Select
                                            isClearable={true}
                                            options={colourOptions}
                                            classNamePrefix='etselect'
                                            placeholder='State'
                                            className={classnames('react-select', { 'is-invalid': errors.state && true })}
                                            {...field}
                                        />
                                    )}
                                />
                                {errors.state && <FormFeedback>{errors.state.message}</FormFeedback>}
                            </Col>
                            <Col md='6' sm='12'>
                                <Controller
                                    id='city'
                                    control={control}
                                    name='city'
                                    render={({ field }) => (
                                        <Select
                                            isClearable={true}
                                            options={colourOptions}
                                            classNamePrefix='etselect'
                                            placeholder='City'
                                            className={classnames('react-select', { 'is-invalid': errors.city && true })}
                                            {...field}
                                        />
                                    )}
                                />
                                {errors.city && <FormFeedback>{errors.city.message}</FormFeedback>}


                            </Col>
                            <Col md='6' sm='12'>
                                <div className="input-group-merge mb-4 input-group">
                                    <Controller
                                        id='poastalCode'
                                        name='poastalCode'
                                        defaultValue=''
                                        control={control}
                                        render={({ field }) => <Input placeholder='Poastal Code' invalid={errors.poastalCode && true} {...field} />}
                                    />
                                    {errors.poastalCode ? <FormFeedback>{errors.poastalCode.message}</FormFeedback> : <span className="cursor-pointer input-group-text"><MapPin /></span>}
                                </div>
                            </Col>
                            <Col md='12' sm='12'>
                                <input type="checkbox" id="chkRemember" name="Remember" value="Remember me" />
                                <label htmlFor="Remember" className="sub-text ml-4">I agree with <b className="text-sky-blue">Privacy Policy</b></label><br />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <div className="demo-inline-spacing">
                            <Button type='submit' color='primary' size='lg' onClick={handleSubmit(onSubmit)} >
                                Save
                            </Button>
                        </div>
                        <div className="demo-inline-spacing">
                            <Button color='secondary' onClick={() => setOpenModal(!openModal)}>
                                Cancel
                            </Button>{' '}
                        </div>
                    </ModalFooter>
                </Form>
            </Modals>


        </>
    )
}

export default ConnectorList