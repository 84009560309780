import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { multiLingColumns } from './column'
import Table from '../../components/elements/DataTable'
import Sidebar from '../../components/structure/Sidebar'
import Header from '../../components/structure/Header'
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap'
import { fetachAllSMList, getAllSMListSelector } from '../../redux/smList'

const SMList = () => {
    const [currentPage, setCurrentPage] = useState(1)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetachAllSMList({
            page: currentPage - 1,
            limit: 10
        }))
    }, [currentPage, dispatch])

    const allSMList = useSelector(getAllSMListSelector)

    // ** Function to handle filter
    const handlePagination = page => {
        console.log(Number(page))
        setCurrentPage(Number(page))
    }

    // ** Pagination Next Component
    const Next = (page) => {
        if (currentPage !== page) {
            setCurrentPage(Number(currentPage) + 1)
        }
    }

    return (
        <>
            <Sidebar />
            <div className="home">
                <Header />
                <div className="container-dashboard">
                    <div className="search">
                        <div className="field" id="searchform">
                            <input type="text" id="searchterm" placeholder="Search here..." />
                            <button type="button" id="search">Search</button>
                        </div>
                    </div>
                    <div className="mt-4">
                        <Card>
                            <CardHeader className='kyc-card'>
                                <CardTitle tag='h4'>SM List</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Table columns={multiLingColumns} currentPage={currentPage} data={Array.isArray(allSMList) ? allSMList : []} handlePagination={handlePagination} Next={Next} />
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SMList