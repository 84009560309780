export const multiLingColumns = [
    {
        name: 'SR.NO.',
        sortable: true,
        maxWidth: '125px',
        selector: row => row.id
    },
    {
        name: 'Name',
        sortable: true,
        minWidth: '150px',
        selector: row => row.name
    },
    {
        name: 'City',
        sortable: true,
        minWidth: '200px',
        selector: row => row.city ? row.city : 'Rajkot'
    },
    {
        name: 'State',
        sortable: true,
        minWidth: '100px',
        selector: row => row.state ? row.state : 'HDFC Bank'
    },
    {
        name: 'Contact No.',
        sortable: true,
        minWidth: '150px',
        selector: row => row.contactNo ? row.contactNo : 'Bussines Loan'
    },

    {
        name: 'Total Billing',
        sortable: true,
        minWidth: '200px',
        selector: row => row.totalBiLing ? row.totalBiLing : '2.3%'
    }

]
