import {
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
    createSlice
} from '@reduxjs/toolkit'
import { getAllLoginCodeList } from '../api'

export const loginCodeAdpate = createEntityAdapter()

export const fetachAllLoginCodeList = createAsyncThunk('users/loginCodeList/fetch', async (options) => {
    const response = await getAllLoginCodeList(options)
    return response
})

const loginCodeState = {
    page: 1,
    total: 0,
    isLoading: false,
    error: null,
    success: false,
    action: '',
    allLoginCodeList: []
}

export const initialLoginCodeList = {
    all: loginCodeState,
    operations: loginCodeState,
    view: loginCodeState,
}

export const loginCodeSlice = createSlice({
    name: 'loginCodeList',
    initialState: initialLoginCodeList,
    reducers: {
        add: loginCodeAdpate.addOne,
        remove: loginCodeAdpate.removeOne
    },
    extraReducers: (builder) => {
        builder.addCase(fetachAllLoginCodeList.pending, (state, action) => {
            state.all.isLoading = true
            state.all.error = null
            state.all.success = false
            state.all.action = 'fetch'
        })
        builder.addCase(fetachAllLoginCodeList.fulfilled, (state, action) => {
            state.all.isLoading = false
            state.all.error = null
            state.all.success = true
            state.all.action = 'fetch'
            state.all.allLoginCodeList = action.payload.payload
            state.all.total = action.payload.total
            state.all.page = action.payload.page
        })
        builder.addCase(fetachAllLoginCodeList.rejected, (state, action) => {
            state.all.isLoading = false
            state.all.error = action.payload
            state.all.success = false
            state.all.action = 'fetch'
        })
    }
})

export const loginCodeListReducer = loginCodeSlice.reducer
export const loginCodeListActions = loginCodeSlice.actions

export const getLoginCodeListState = (rootState) => rootState[loginCodeSlice.name]

export const getAllLoginCodeListSelector = createSelector(
    getLoginCodeListState,
    (state) => state.all.allLoginCodeList
)