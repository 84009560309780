import {
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
    createSlice
} from '@reduxjs/toolkit'
import { getAllUsers, kycDataUpload, paymentPendingUsers, reSubmissionUsers } from '../api'

export const paymentAdapter = createEntityAdapter()

export const fetchUsers = createAsyncThunk('users/all/fetch', async (options) => {
    const response = await getAllUsers(options)
    return response
})

export const fetchPendingUser = createAsyncThunk('users/pending/fetch', async (options) => {
    const response = await paymentPendingUsers(options)
    return response
})

export const fetchReSubmissionUser = createAsyncThunk('users/resubmission/fetch', async (options) => {
    const response = await reSubmissionUsers(options)
    return response
})

export const kycUpload = createAsyncThunk('kyc/upload', async (options) => {
    const response = await kycDataUpload(options)
    console.log(response)
    return response
})

const paymentState = {
    page: 1,
    total: 0,
    isLoading: false,
    error: null,
    success: false,
    action: '',
    allPayments: [],
    pendingPayments: [],
    resubmissionPayments: []
}

export const initialInterviewersState = {
    all: paymentState,
    operations: paymentState,
    view: paymentState,
}

export const paymentSlice = createSlice({
    name: 'payment',
    initialState: initialInterviewersState,
    reducers: {
        add: paymentAdapter.addOne,
        remove: paymentAdapter.removeOne
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUsers.pending, (state, action) => {
            state.all.isLoading = true
            state.all.error = null
            state.all.success = false
            state.all.action = 'fetch'
        })
        builder.addCase(fetchUsers.fulfilled, (state, action) => {
            state.all.isLoading = false
            state.all.error = null
            state.all.success = true
            state.all.action = 'fetch'
            state.all.allPayments = action.payload.payload
            state.all.total = action.payload.total
            state.all.page = action.payload.page
        })
        builder.addCase(fetchUsers.rejected, (state, action) => {
            state.all.isLoading = false
            state.all.error = action.error
            state.all.success = false
            state.all.action = 'fetch'
        })
        builder.addCase(fetchPendingUser.pending, (state, action) => {
            state.operations.isLoading = true
            state.operations.error = null
            state.operations.success = false
            state.operations.action = 'fetch'
        }
        )
        builder.addCase(fetchPendingUser.fulfilled, (state, action) => {
            state.operations.isLoading = false
            state.operations.error = null
            state.operations.success = true
            state.operations.action = 'fetch'
            state.operations.pendingPayments = action.payload.payload
            state.operations.total = action.payload.total
            state.operations.page = action.payload.page
        }
        )
        builder.addCase(fetchPendingUser.rejected, (state, action) => {
            state.operations.isLoading = false
            state.operations.error = action.error
            state.operations.success = false
            state.operations.action = 'fetch'
        })
        builder.addCase(fetchReSubmissionUser.pending, (state, action) => {
            state.view.isLoading = true
            state.view.error = null
            state.view.success = false
            state.view.action = 'fetch'
        }
        )
        builder.addCase(fetchReSubmissionUser.fulfilled, (state, action) => {
            state.view.isLoading = false
            state.view.error = null
            state.view.success = true
            state.view.action = 'fetch'
            state.view.resubmissionPayments = action.payload.payload
            state.view.total = action.payload.total
            state.view.page = action.payload.page
        }
        )
        builder.addCase(fetchReSubmissionUser.rejected, (state, action) => {
            state.view.isLoading = false
            state.view.error = action.error
            state.view.success = false
            state.view.action = 'fetch'
        }
        )
    }

})

export const paymentReducer = paymentSlice.reducer
export const paymentActions = paymentSlice.actions

export const getPaymentState = (rootState) => rootState[paymentSlice.name]

export const getAllPayments = createSelector(
    getPaymentState,
    (state) => state.all.allPayments
)

export const getPendingPaymentsSelector = createSelector(
    getPaymentState,
    (state) => state.operations.pendingPayments
)

export const getReSubmissionPaymentsSelector = createSelector(
    getPaymentState,
    (state) => state.view.resubmissionPayments
)
