// ** React Imports
import { Fragment } from 'react'

// ** Third Party Components
import { Check, X, } from 'react-feather'


export const SuccessToast = ({ title, text }) => (
    <Fragment>
        <div className='toastify-header'>
            <div className='title-wrapper'>
                <div className="avatar icon-success avatar-sm"><span className="avatar-content">
                    <Check className='toast-icon' />
                </span></div>
                <h6 className='toast-title text-success'>{title}</h6>
            </div>
        </div>
        <div className='toastify-body'>
            <span role='img' aria-label='toast-text'>
                {text}
            </span>
        </div>
    </Fragment>
)

export const ErrorToast = ({ text }) => (
    <Fragment>
        <div className='toastify-header'>
            <div className='title-wrapper'>
                <div className="avatar icon-danger avatar-sm"><span className="avatar-content">
                    <X className='toast-icon' />
                </span></div>
                <h6 className='toast-title text-danger'>Error!</h6>
            </div>
        </div>
        <div className='toastify-body'>
            <span role='img' aria-label='toast-text'>
                {text}
            </span>
        </div>
    </Fragment>
)