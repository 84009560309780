import { Edit3 } from "react-feather"

export const multiLingColumns = [
    {
        name: 'SR.NO.',
        sortable: true,
        maxWidth: '125px',
        selector: row => row.userId
    },
    {
        name: 'Name',
        sortable: true,
        minWidth: '200px',
        selector: row => row.name
    },
    {
        name: 'Contact No.',
        sortable: true,
        minWidth: '200px',
        selector: row => row.contact
    },
    {
        name: 'City',
        sortable: true,
        minWidth: '100px',
        selector: row => row.city
    },
    {
        name: 'State',
        sortable: true,
        minWidth: '150px',
        selector: row => row.state
    },
    {
        name: 'Action',
        sortable: true,
        minWidth: '150px',
        cell: () => {
            return (
                <span className="action"><Edit3 /></span>
            )
        }
    }
]
