export const BASE_URL = 'https://api.orendafinserv.com/';

export const API_ROUTES = {
    USERS_REFFERED_ENDPOINT: '/user/all',
    KYC_UPLOAD: '/kyc_status/upload',
    USERS_DISBURSEMENT_ENDPOINT: '/disbursement/all',
    ADD_USER_DISBURSEMENT: '/disbursement/dsa/insert',
    LOGIN_CODE_LIST: '/login_code_list/all',
    CONNECTOR_LIST: '/user/connector/OFLBNZPM2501F2021',
    ADD_CONNECTOR: '/user/insert/connector',
    SM_LIST: '/sm_list/all',
    PAYOUT_GRID_LIST: '/login_code_list/all',
    NORMAL_ADVANCED_GRID: '/normal_advanced_grid',
    PAYMENT_PENDING_USERS: '/dashboard/payment/pending',
    RE_SUBMISSION_USERS: '/dashboard/resubmission'
}

