import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import Select from 'react-select'
import Header from '../../components/structure/Header'
import Sidebar from '../../components/structure/Sidebar'
import { Card, CardHeader, CardTitle, CardBody, Row, Col, Input, Button, Form, Label, ModalBody, ModalFooter, FormFeedback } from 'reactstrap'
import { Briefcase, Calendar, MapPin, Phone, User } from 'react-feather'
import Table from '../../components/elements/DataTable'
import { multiLingColumns } from './column'
import { addUserDisbursementdata, fetachAllUserDisbursement, getAllUsersDisbursementSelector } from '../../redux/disbursement'
import Modals from '../../components/elements/Modal'
import classnames from 'classnames'
import { Slide, toast } from 'react-toastify'
import { ErrorToast, SuccessToast } from '../../components/elements/Toastify'

const colourOptions = [
    { value: 'a', label: 'A' },
    { value: 'b', label: 'B' },
    { value: 'c', label: 'C' },
    { value: 'd', label: 'D' },
    { value: 'e', label: 'E' }
]

const Disbursement = () => {
    const [currentPage, setCurrentPage] = useState(1)
    const [statusValue, setStatusValue] = useState('Yes')
    const [searchValue, setSearchValue] = useState('')
    const [openModal, setOpenModal] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetachAllUserDisbursement({
            page: currentPage,
            limit: 10
        }))
    }, [currentPage, dispatch])

    const Schema = yup.object().shape({
        nameOfBorrower: yup.string().required('Name is required'),
        lanNo: yup.string().required('Lan No is required'),
        loanAmount: yup.string().required('Loan Amount is required'),
        pinCode: yup.string().required('Pin Code is required'),
        bankname: yup.object().required('Bank Name is required'),
        product: yup.object().required('Product is required'),
        state: yup.object().required('State is required'),
        month: yup.string().required('Month is required'),
        case_connector: yup.bool().required('Case Connector is required').default(true),
        mobile: yup.string().required('Mobile is required'),
        connector: yup.object().required('Connector is required'),
    })

    const {
        reset,
        control,
        handleSubmit,
        formState: { errors }
    } = useForm({ mode: 'onChange', resolver: yupResolver(Schema) })



    const onStatusChange = (e) => {
        setStatusValue(e.currentTarget.value)
    }

    const onSubmit = data => {
        console.log('data', data)
        const object = {
            // dsa_unique_id: data.dsa_unique_id,
            connector_id: data.connector,
            customer_name: data.nameOfBorrower,
            lan: data.lanNo,
            bank_name: data.bankname.value,
            product: data.product.value,
            month_year: data.month,
            loan_amount_dsa: data.loanAmount,
            location: data.state.value,
            payment_mode: data.paymentMode,
        }
        dispatch(addUserDisbursementdata(object)).then((res) => {
            if (res.payload.status === 200) {
                toast.success(<SuccessToast title="Success!" text="Disbursement Added Successfully" />, { icon: false, hideProgressBar: true, transition: Slide })
                dispatch(fetachAllUserDisbursement({
                    allowPagination: false
                }))
                reset()
                setOpenModal(!openModal)
            }
        }
        ).catch((err) => {
            console.log(err)
            reset()
            toast.error(<ErrorToast title="Error!" text={err.message} />, { icon: false, hideProgressBar: true, transition: Slide })
            setOpenModal(!openModal)
        })
    }
    const allUsersDisbursement = useSelector(getAllUsersDisbursementSelector)
    const allUsers = allUsersDisbursement ? allUsersDisbursement : []

    const handleFilter = e => {
        setSearchValue(e.target.value)

        dispatch(
            fetachAllUserDisbursement({
                allowPagination: true,
                search: e.target.value
            })
        )
    }


    // ** Function to handle filter
    const handlePagination = page => {
        console.log(Number(page))
        setCurrentPage(Number(page))
    }

    // ** Pagination Next Component
    const Next = (page) => {
        if (currentPage !== page) {
            setCurrentPage(Number(currentPage) + 1)
        }
    }

    const dataRender = () => {
        const filters = {
            search: searchValue
        }

        const isFiltered = Object.keys(filters).some(function (k) {
            return filters[k].length > 0
        })

        if (allUsers.length > 0) {
            return allUsers
        } else if (allUsers.length === 0 && isFiltered) {
            return []
        } else {
            return allUsers.slice(0, 25)
        }

    }


    return (
        <>
            <Sidebar />
            <Modals />
            <div className="home">
                <Header />

                <div className="container-dashboard">
                    <div className="search">
                        <div className="field" id="searchform">
                            <input type="text" id="searchterm" placeholder="Search here..." value={searchValue}
                                onChange={handleFilter} />
                            <button type="button" id="search">Search</button>
                        </div>
                    </div>
                    <div className="mt-4">
                        <Card>
                            <CardHeader className='kyc-card'>
                                <CardTitle tag='h4'>Disbursement Data</CardTitle>
                                <div className="actions">
                                    <div className="demo-inline-spacing">
                                        <Button color='flat-success' onClick={() => setOpenModal(!openModal)}> <span className='mr-4'><Calendar /></span> Add Disbursement Date</Button>
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Table columns={multiLingColumns} currentPage={currentPage} data={allUsers} handlePagination={handlePagination} Next={Next} />
                            </CardBody>
                        </Card>
                    </div>

                </div>


            </div>

            {/* Add Disbursement Modal */}
            <Modals openModal={openModal} setOpenModal={setOpenModal} title='Add Disbursement Data' >
                <Form>

                    <ModalBody>
                        <Row>
                            <Col md='6' sm='12'>
                                <div className="input-group-merge mb-4 input-group">
                                    <Controller
                                        id='nameOfBorrower'
                                        name='nameOfBorrower'
                                        defaultValue=''
                                        control={control}
                                        render={({ field }) => <Input placeholder='Name Of Borrower' invalid={errors.nameOfBorrower && true} {...field} />}
                                    />
                                    {errors.nameOfBorrower ? <FormFeedback>{errors.nameOfBorrower.message}</FormFeedback> : <span className="cursor-pointer input-group-text"><User /></span>}
                                </div>
                            </Col>
                            <Col md='6' sm='12'>
                                <div className="input-group-merge mb-4 input-group">
                                    <Controller
                                        id='lanNo'
                                        name='lanNo'
                                        defaultValue=''
                                        control={control}
                                        render={({ field }) => <Input placeholder='Lan No.' invalid={errors.lanNo && true} {...field} />}
                                    />
                                    {errors.lanNo ? <FormFeedback>{errors.lanNo.message}</FormFeedback> : <span className="cursor-pointer input-group-text"><Briefcase /></span>}
                                </div>
                            </Col>
                            <Col md='6' sm='12'>
                                <div className="input-group-merge mb-4 input-group">
                                    <Controller
                                        id='loanAmount'
                                        name='loanAmount'
                                        defaultValue=''
                                        control={control}
                                        render={({ field }) => <Input placeholder='Loan Amount' invalid={errors.loanAmount && true} {...field} />}
                                    />
                                    {errors.loanAmount ? <FormFeedback>{errors.loanAmount.message}</FormFeedback> : <span className="cursor-pointer input-group-text"><svg style={{ fontSize: '22px' }} xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 36 36"><path fill="currentColor" d="M32 15h-1V9a1 1 0 0 0-1-1H6a1 1 0 0 1-1-.82v-.36A1 1 0 0 1 6 6h23.58a1 1 0 0 0 0-2H6a3 3 0 0 0-3 3a3.08 3.08 0 0 0 0 .36v20.57A4.1 4.1 0 0 0 7.13 32H30a1 1 0 0 0 1-1v-6h1a1 1 0 0 0 1-1v-8a1 1 0 0 0-1-1Zm-3 15H7.13A2.11 2.11 0 0 1 5 27.93V9.88A3.11 3.11 0 0 0 6 10h23v5h-7a5 5 0 0 0 0 10h7Zm2-7h-9a3 3 0 0 1 0-6h9Z" className="clr-i-outline clr-i-outline-path-1" /><circle cx="23.01" cy="20" r="1.5" fill="currentColor" className="clr-i-outline clr-i-outline-path-2" /><path fill="none" d="M0 0h36v36H0z" /></svg></span>}
                                </div>
                            </Col>
                            <Col md='6' sm='12'>
                                <div className="input-group-merge mb-4 input-group">
                                    <Controller
                                        id='pinCode'
                                        name='pinCode'
                                        defaultValue=''
                                        control={control}
                                        render={({ field }) => <Input placeholder='Borrowers City Pincode' invalid={errors.pinCode && true} {...field} />}
                                    />
                                    {errors.pinCode ? <FormFeedback>{errors.pinCode.message}</FormFeedback> : <span className="cursor-pointer input-group-text"><MapPin /></span>}
                                </div>
                            </Col>
                            <Col md='6' sm='12' className='mb-4'>
                                <Controller
                                    id='bankname'
                                    control={control}
                                    name='bankname'
                                    render={({ field }) => (
                                        <Select
                                            isClearable={true}
                                            options={colourOptions}
                                            classNamePrefix='etselect'
                                            placeholder='Bank/NBFC Name'
                                            className={classnames('react-select', { 'is-invalid': errors.bankname && true })}
                                            {...field}
                                        />
                                    )}
                                />
                                {errors.bankname && <FormFeedback>{errors.bankname.message}</FormFeedback>}
                            </Col>
                            <Col md='6' sm='12' className='mb-4'>
                                <Controller
                                    id='product'
                                    control={control}
                                    name='product'
                                    render={({ field }) => (
                                        <Select
                                            isClearable={true}
                                            options={colourOptions}
                                            classNamePrefix='etselect'
                                            placeholder='Product'
                                            className={classnames('react-select', { 'is-invalid': errors.product && true })}
                                            {...field}
                                        />
                                    )}
                                />
                                {errors.product && <FormFeedback>{errors.product.message}</FormFeedback>}
                            </Col>
                            <Col md='6' sm='12'>
                                <div className="input-group-merge mb-4 input-group">
                                    <Controller
                                        id='month'
                                        name='month'
                                        defaultValue=''
                                        control={control}
                                        render={({ field }) => <Input placeholder='Month of Disbursement' invalid={errors.month && true} {...field} />}
                                    />
                                    {errors.month ? <FormFeedback>{errors.pinCode.message}</FormFeedback> : <span className="cursor-pointer input-group-text"><Calendar /></span>}
                                </div>
                            </Col>
                            <Col md='6' sm='12'>
                                <label htmlFor="">Whether case sourced by Connector</label>
                                <div className="d-flex">
                                    <div className='form-check'>
                                        <Input type='radio' id='radio-primary' name='case_connector' defaultChecked onChange={onStatusChange} value={"Yes"} />
                                        <Label className='form-check-label' htmlFor='case_connector'>
                                            Yes
                                        </Label>
                                    </div>
                                    <div className='form-check ml'>
                                        <Input type='radio' id='radio-primary' name='case_connector' onChange={onStatusChange} value={"No"} />
                                        <Label className='form-check-label' htmlFor='ex1-inactive'>
                                            No
                                        </Label>
                                    </div>
                                </div>
                            </Col>
                            <Col md='6' sm='12'>
                                <div className="input-group-merge mb-4 input-group">
                                    <Controller
                                        id='mobile'
                                        name='mobile'
                                        defaultValue=''
                                        control={control}
                                        render={({ field }) => <Input placeholder='Mobile No.(Optional)' invalid={errors.mobile && true} {...field} />}
                                    />
                                    {errors.mobile ? <FormFeedback>{errors.mobile.message}</FormFeedback> : <span className="cursor-pointer input-group-text"><Phone /></span>}
                                </div>
                            </Col>
                            <Col md='6' sm='12' className='mb-4'>
                                <Controller
                                    id='connector'
                                    control={control}
                                    name='connector'
                                    render={({ field }) => (
                                        <Select
                                            isClearable={true}
                                            options={colourOptions}
                                            classNamePrefix='etselect'
                                            placeholder='Select Connector'
                                            className={classnames('react-select', { 'is-invalid': errors.product && true })}
                                            {...field}
                                        />
                                    )}
                                />
                                {errors.connector && <FormFeedback>{errors.connector.message}</FormFeedback>}
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <div className="demo-inline-spacing">
                            <Button color='primary' type='button' onClick={handleSubmit(onSubmit)}>
                                Submit
                            </Button>{' '}
                        </div>
                        <div className="demo-inline-spacing">
                            <Button color='secondary' onClick={() => setOpenModal(!openModal)}>
                                Cancel
                            </Button>{' '}
                        </div>
                    </ModalFooter>
                </Form>

            </Modals>

        </>
    )
}

export default Disbursement