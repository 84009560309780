import {
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
    createSlice
} from '@reduxjs/toolkit'
import { getAllUsersDisbursement, addUserDisbursement } from '../api'

export const userDisbursementAdpater = createEntityAdapter()

export const fetachAllUserDisbursement = createAsyncThunk('users/disbursement/fetch', async (options) => {
    const response = await getAllUsersDisbursement(options)
    return response
})

export const addUserDisbursementdata = createAsyncThunk('users/disbursement/add', async (options) => {
    const response = await addUserDisbursement(options)
    return response
})

const userDisbursementState = {
    page: 1,
    total: 0,
    isLoading: false,
    error: null,
    success: false,
    action: '',
    allUserDisbursement: []
}

export const initialUserDisbursementState = {
    all: userDisbursementState,
    operations: userDisbursementState,
    view: userDisbursementState,
}

export const userDisbursementSlice = createSlice({
    name: 'userDisbursement',
    initialState: initialUserDisbursementState,
    reducers: {
        add: userDisbursementAdpater.addOne,
        remove: userDisbursementAdpater.removeOne
    },
    extraReducers: (builder) => {
        builder.addCase(fetachAllUserDisbursement.pending, (state, action) => {
            state.all.isLoading = true
            state.all.error = null
            state.all.success = false
            state.all.action = 'fetch'
        })
        builder.addCase(fetachAllUserDisbursement.fulfilled, (state, action) => {
            state.all.isLoading = false
            state.all.error = null
            state.all.success = true
            state.all.action = 'fetch'
            state.all.allUserDisbursement = action.payload.payload
            state.all.total = action.payload.total
            state.all.page = action.payload.page
        })
        builder.addCase(fetachAllUserDisbursement.rejected, (state, action) => {
            state.all.isLoading = false
            state.all.error = action.payload
            state.all.success = false
            state.all.action = 'fetch'
        })
        builder.addCase(addUserDisbursementdata.pending, (state, action) => {
            state.operations.isLoading = true
            state.operations.error = null
            state.operations.success = false
            state.operations.action = 'add'
        })
        builder.addCase(addUserDisbursementdata.fulfilled, (state, action) => {
            state.operations.isLoading = false
            state.operations.error = null
            state.operations.success = true
            state.operations.action = 'add'
        })
        builder.addCase(addUserDisbursementdata.rejected, (state, action) => {
            state.operations.isLoading = false
            state.operations.error = action.payload
            state.operations.success = false
            state.operations.action = 'add'
        })
    }
})

export const userDisbursementReducer = userDisbursementSlice.reducer
export const userDisbursementAction = userDisbursementSlice.actions

export const getUserDisbursementState = (rootState) => rootState[userDisbursementSlice.name]

export const getAllUsersDisbursementSelector = createSelector(
    getUserDisbursementState,
    (state) => state.all.allUserDisbursement
)