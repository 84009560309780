import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import userReducer from './user';
import { paymentReducer } from './payment';
import { userRefferdReducer } from './userRefferd';
import { userDisbursementReducer } from './disbursement';
import { loginCodeListReducer } from './loginCodeList';
import { connectorListReducer } from './connector';
import { smListReducer } from './smList';
import { modalReducer } from './modal';
import { payoutGridReducer } from './payoutGrid';
import storage from 'redux-persist/lib/storage';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'

const reducer = combineReducers({
  userReducer,
  payment: paymentReducer,
  userRefferd: userRefferdReducer,
  userDisbursement: userDisbursementReducer,
  loginCodeList: loginCodeListReducer,
  connectorList: connectorListReducer,
  smList: smListReducer,
  modal: modalReducer,
  payoutGridList: payoutGridReducer,
})

const persistConfig = {
  key: 'root',
  storage,
};


// For deleting persist storage :-

// const rootReducer = (state, action) => {
//   storage.removeItem('persist:root')
//   return reducer(undefined, action)
// }

// const persistedReducer = persistReducer(persistConfig, rootReducer);
const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})


export default store