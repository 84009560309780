import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { BASE_URL } from '../../constants'
import Input from '../Input'

function ForgotPassword({ setVisibleComponent }) {
    const [email, setEmail] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const [isErrorVisible, setIsErrorVisible] = useState(false)

    useEffect(() => {
        const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        if (email.length && regex.test(email))
            setErrorMessage('')
        else
            setErrorMessage('Please enter valid email')
    }, [email])

    const resetPassword = async () => {
        setIsErrorVisible(true)
        if (errorMessage) return

        try {
            const response = await axios.post(`${BASE_URL}user/password/forgotpassword`, {
                "idOrEmail": email
            })
            console.log(`resetPassword ${JSON.stringify(response)}`)
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Check your email'
            })
        } catch (error) {
            console.error(`resetPassword ${error}`)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Invalid email!',
            })
        }
    }

    return (
        <div className="form-forgot-password">
            <div className="headers">
                <div className="title">Forgot Password</div>
                <div><a href="" className="close-modal">&times;</a></div>
            </div>
            <hr />
            <Input
                id="email"
                type="email"
                placeholder='Email'
                value={email}
                onChange={setEmail}
                icon={<img src={require("./../../assets/images/Combined-Shapename.png")} alt="" />} />
            {isErrorVisible && <p className='error-text-msg'>{errorMessage}</p>}
            <button onClick={resetPassword} type="text" className="submit">Reset Password</button>
            <div className="form-footer">
                <div className="sub-text">Back to <a onClick={() => setVisibleComponent("SignIn")} className="sign-up"><b> Sign in?</b></a></div>
            </div>
        </div>
    )
}

export default ForgotPassword