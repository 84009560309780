export const multiLingColumns = [
    {
        name: 'SR.NO.',
        sortable: true,
        maxWidth: '125px',
        selector: row => row.smListId
    },
    {
        name: 'Financer',
        sortable: true,
        minWidth: '200px',
        selector: row => row.bank_nbfc_name
    },
    {
        name: 'Product',
        sortable: true,
        minWidth: '200px',
        selector: row => row.product
    },
    {
        name: 'SM Name',
        sortable: true,
        minWidth: '100px',
        selector: row => row.sm_name
    },
    {
        name: 'SM Contact No.',
        sortable: true,
        minWidth: '150px',
        selector: row => row.contact
    },
]
