import React, { useState } from 'react'
import Header from '../../components/structure/Header'
import Sidebar from '../../components/structure/Sidebar'
import { Card, CardHeader, CardTitle, CardBody, TabContent, TabPane } from 'reactstrap'
import Tab from '../../components/elements/Tab'
import L1Down from './L1Down'
import L2Down from './L2Down'


const UserReffered = () => {
    const tabs = [
        { id: '1', name: 'L-1 Down' },
        { id: '2', name: 'L-2 Down' },
    ]
    const [active, setActive] = useState('1')
    return (
        <>
            <Sidebar />
            <div className="home">
                <Header />
                <div className="container-dashboard">
                    <div className="search">
                        <div className="field" id="searchform">
                            <input type="text" id="searchterm" placeholder="Search here..." />
                            <button type="button" id="search">Search</button>
                        </div>
                    </div>
                    <div className="mt-4">
                        <Card>
                            <CardHeader>
                                <CardTitle tag='h4'>User Reffered</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Tab tabs={tabs} setActive={setActive} activeTab={active}>
                                    <TabContent className='py-50' activeTab={active}>
                                        <TabPane tabId='1'>
                                            <L1Down />
                                        </TabPane>
                                        <TabPane tabId='2'>
                                            <L2Down />
                                        </TabPane>
                                    </TabContent>
                                </Tab>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserReffered