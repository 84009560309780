import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { multiLingColumns } from './column'
import { fetchReSubmissionUser, fetchUsers, getAllPayments, getReSubmissionPaymentsSelector } from '../../../redux/payment'
import Table from '../../../components/elements/DataTable'

const ResubmissionCases = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchReSubmissionUser({
      allowPagination: false
    }))
  }, [dispatch])

  const allUsers = useSelector(getReSubmissionPaymentsSelector)
  console.log('allUsers', allUsers)
  // ** Function to handle filter
  const handlePagination = page => {
    console.log(Number(page))
    setCurrentPage(Number(page))
  }

  // ** Pagination Next Component
  const Next = (page) => {
    if (currentPage !== page) {
      setCurrentPage(Number(currentPage) + 1)
    }
  }

  return (
    <Table columns={multiLingColumns} currentPage={currentPage} data={Array.isArray(allUsers) ? allUsers : []} handlePagination={handlePagination} Next={Next} />
  )
}

export default ResubmissionCases
