import axios from "axios";
import { BASE_URL } from './../constants';

export default class AxiosWrapper {
    static token = null;

    constructor() {
        AxiosWrapper.retrieveToken();
    }

    static retrieveToken = async () => {
        try {
            this.token = localStorage.getItem('token')
            console.log('>>>', JSON.stringify(this.token))
            console.log(`Bearer Token: ${JSON.stringify(this.token)}`)
        } catch (e) {
            console.error(e);
        }
    }

    static get = ({ endpoint, page, limit = 10 }) => {
        let url = `${BASE_URL}${endpoint}`
        if (page) {
            url += `?page=${page}&limit=${limit}`
        }
        console.log('token', this.token)
        return axios.get(url, {
            headers: {
                'Authorization': `Bearer ${this.token}`
            }
        })
    }

    static post = ({ endpoint, body }) => {
        console.log(`${BASE_URL}${endpoint}`)
        return axios.post(`${BASE_URL}${endpoint}`, body, {
            headers: {
                'Authorization': `Bearer ${this.token}`
            }
        })
    }

    static put = ({ endpoint, id, body }) => {
        let url = `${BASE_URL}${endpoint}`
        if (id) {
            url += `/${id}`
        }
        return axios.put(url, body, {
            headers: {
                'Authorization': `Bearer ${this.token}`
            }
        })
    }

    static delete = ({ endpoint, id }) => {
        return axios.delete(`${BASE_URL}${endpoint}/${id}`, {
            headers: {
                'Authorization': `Bearer ${this.token}`
            }
        })
    }
}