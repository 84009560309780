export const multiLingColumns = [
    {
        name: 'SR.NO.',
        sortable: true,
        maxWidth: '125px',
        selector: row => row.id
    },
    {
        name: 'Case Name',
        sortable: true,
        minWidth: '150px',
        selector: row => row.name
    },
    {
        name: 'Loan Amount',
        sortable: true,
        minWidth: '200px',
        selector: row => row.loan_amount ? row.loan_amount : '4000'
    },
    {
        name: 'Banker',
        sortable: true,
        minWidth: '100px',
        selector: row => row.banker ? row.banker : 'HDFC Bank'
    },
    {
        name: 'Prouduct',
        sortable: true,
        minWidth: '150px',
        selector: row => row.prouduct ? row.prouduct : 'Bussines Loan'
    },

    {
        name: 'Gross Payout',
        sortable: true,
        minWidth: '200px',
        selector: row => row.gross_payout ? row.gross_payout : '2.3%'
    },
    {
        name: 'Utr Number',
        sortable: true,
        minWidth: '150px',
        selector: row => row.utr_number ? row.utr_number : '68514',
    }
]
