import React from 'react'
import { Row, Col, Input, Button } from 'reactstrap'
import { ChevronDown, CheckSquare, XSquare } from 'react-feather'
import { Pagination } from './Pagination'
import DataTable from 'react-data-table-component'

const Table = (props) => {
    const { columns, currentPage, data, handlePagination, Next, editInputs = false } = props
    const CustomPagination = () => (
        <>
            {editInputs &&
                <Row className='edit-input-container'>
                    <Col md={2}>
                        <div className="input-group-merge mb-2 input-group">
                            <Input type='number' id='panNo' name='panNo' placeholder='Pan No.' />
                            <span className="cursor-pointer input-group-text"><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32"><path fill="currentColor" d="M16 4a5 5 0 1 1-5 5a5 5 0 0 1 5-5m0-2a7 7 0 1 0 7 7a7 7 0 0 0-7-7zm10 28h-2v-5a5 5 0 0 0-5-5h-6a5 5 0 0 0-5 5v5H6v-5a7 7 0 0 1 7-7h6a7 7 0 0 1 7 7z" /></svg></span>
                        </div>
                    </Col>
                    <Col md={2}>
                        <div className="input-group-merge mb-2 input-group">
                            <Input type='number' id='bankAccountNo' name='bankAccountNo' placeholder='Bank Account No.' />
                            <span className="cursor-pointer input-group-text"><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><path fill="currentColor" d="M8 5a.75.75 0 1 0 0-1.5A.75.75 0 0 0 8 5Zm.736-3.545a1.167 1.167 0 0 0-1.472 0L2.262 5.52C1.654 6.016 2.004 7 2.788 7H3v4.2c-.594.303-1 .92-1 1.633v.667a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 .5-.5v-.667c0-.712-.406-1.33-1-1.633V7h.212c.784 0 1.134-.985.525-1.48l-5-4.066Zm-.84.776a.167.167 0 0 1 .21 0L12.741 6H3.258l4.638-3.77ZM12 11h-1V7h1v4Zm-2 0H8.5V7H10v4Zm-2.5 0H6V7h1.5v4Zm4.667 1c.46 0 .833.373.833.833V13H3v-.167c0-.46.373-.833.833-.833h8.334ZM5 11H4V7h1v4Z" /></svg></span>
                        </div>
                    </Col>
                    <Col md={2}>
                        <div className="input-group-merge mb-2 input-group">
                            <Input type='number' id='ifscCode' name='ifscCode' placeholder='IFSC Code' />
                            <span className="cursor-pointer input-group-text"><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><path fill="currentColor" d="M8 5a.75.75 0 1 0 0-1.5A.75.75 0 0 0 8 5Zm.736-3.545a1.167 1.167 0 0 0-1.472 0L2.262 5.52C1.654 6.016 2.004 7 2.788 7H3v4.2c-.594.303-1 .92-1 1.633v.667a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 .5-.5v-.667c0-.712-.406-1.33-1-1.633V7h.212c.784 0 1.134-.985.525-1.48l-5-4.066Zm-.84.776a.167.167 0 0 1 .21 0L12.741 6H3.258l4.638-3.77ZM12 11h-1V7h1v4Zm-2 0H8.5V7H10v4Zm-2.5 0H6V7h1.5v4Zm4.667 1c.46 0 .833.373.833.833V13H3v-.167c0-.46.373-.833.833-.833h8.334ZM5 11H4V7h1v4Z" /></svg></span>
                        </div>
                    </Col>
                    <Col md={1}>
                        <div className="demo-inline-spacing mb-2">
                            <Button color='info' type='submit'>Upload</Button>
                        </div>
                    </Col>
                    <Col md={2}>
                        <div className="demo-inline-spacing mb-2">
                            <Button className='btn-icon' color='success'>
                                <CheckSquare size={16} />
                            </Button>
                            <Button className='btn-icon xSqure' color='warning'>
                                <XSquare size={16} />
                            </Button>
                        </div>
                    </Col>
                </Row>
            }
            <Pagination currentPage={currentPage} totalRecords={data.length} perPage={7} pageChangeHandler={handlePagination} Next={Next} />
        </>

    )
    return (
        <div className='react-dataTable'>
            <DataTable
                noHeader
                pagination
                selectableRowsNoSelectAll
                columns={columns}
                className='react-dataTable'
                paginationPerPage={7}
                sortIcon={<ChevronDown size={10} />}
                paginationDefaultPage={currentPage}
                paginationComponent={CustomPagination}
                data={data ? data : []}
            />
        </div>
    )
}

export default Table