import {
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
    createSlice
} from '@reduxjs/toolkit'
import { getAllPayoutGirdList, getOnePayoutGird, updatePayoutGird } from '../api'

export const payoutGridListAdapter = createEntityAdapter()

export const fetachPayoutGridList = createAsyncThunk('payoutGrid/fetch', async (options) => {
    const response = await getAllPayoutGirdList(options)
    return response
}
)

export const fetchOnePayoutGrid = createAsyncThunk('payoutGrid/fetchOne', async (id) => {
    const response = await getOnePayoutGird(id)
    return response
})

export const updateOnePayoutGrid = createAsyncThunk('payoutGrid/updateOne', async (payload) => {
    const response = await updatePayoutGird(payload)
    return response
})

const payoutGridListState = {
    page: 1,
    total: 0,
    isLoading: false,
    error: null,
    success: false,
    action: '',
    payoutGrid: {},
    allPayoutGridList: []
}

export const initialPayoutGridList = {
    all: payoutGridListState,
    operations: payoutGridListState,
    view: payoutGridListState,
}

export const payoutGridSlice = createSlice({
    name: 'payoutGridList',
    initialState: initialPayoutGridList,
    reducers: {
        add: payoutGridListAdapter.addOne,
        remove: payoutGridListAdapter.removeOne
    },
    extraReducers: (builder) => {
        builder.addCase(fetachPayoutGridList.pending, (state, action) => {
            state.all.isLoading = true
            state.all.error = null
            state.all.success = false
            state.all.action = 'fetch'
        })
        builder.addCase(fetachPayoutGridList.fulfilled, (state, action) => {
            state.all.isLoading = false
            state.all.error = null
            state.all.success = true
            state.all.action = 'fetch'
            state.all.allPayoutGridList = action.payload.payload
            state.all.total = action.payload.total
            state.all.page = action.payload.page
        })
        builder.addCase(fetachPayoutGridList.rejected, (state, action) => {
            state.all.isLoading = false
            state.all.error = action.payload
            state.all.success = false
            state.all.action = 'fetch'
        })
        builder.addCase(fetchOnePayoutGrid.pending, (state, action) => {
            state.view.isLoading = true
            state.view.error = null
            state.view.success = false
            state.view.action = 'fetch'
        })
        builder.addCase(fetchOnePayoutGrid.fulfilled, (state, action) => {
            state.view.isLoading = false
            state.view.error = null
            state.view.success = true
            state.view.payoutGrid = action.payload.payload
        })
        builder.addCase(fetchOnePayoutGrid.rejected, (state, action) => {
            state.view.isLoading = false
            state.view.error = action.payload
            state.view.success = false
            state.view.action = 'fetch'
        })
        builder.addCase(updateOnePayoutGrid.pending, (state, action) => {
            state.operations.isLoading = true
            state.operations.error = null
            state.operations.success = false
            state.operations.action = 'update'
        })
        builder.addCase(updateOnePayoutGrid.fulfilled, (state, action) => {
            state.operations.isLoading = false
            state.operations.error = null
            state.operations.success = true
            state.operations.action = 'update'
            state.operations.payoutGrid = action.payload.payload
        })
        builder.addCase(updateOnePayoutGrid.rejected, (state, action) => {
            state.operations.isLoading = false
            state.operations.error = action.payload
            state.operations.success = false
            state.operations.action = 'update'
        }
        )

    }
})

export const payoutGridReducer = payoutGridSlice.reducer
export const payoutGridActions = payoutGridSlice.actions

export const getPayoutGridState = (rootstate) => rootstate[payoutGridSlice.name]

export const getAllPayoutGridListSelector = createSelector(
    getPayoutGridState,
    (state) => state.all.allPayoutGridList
)

export const getOnePayoutGirdSelector = createSelector(
    getPayoutGridState,
    (state) => state.view.payoutGrid
)

export const getPayoutGridOperationSelector = createSelector(
    getPayoutGridState,
    (state) => state.operations
)