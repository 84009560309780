import React, { useState } from 'react'
import SignIn from '../../components/Auth/SignIn'
import SignUp from '../../components/Auth/SignUp'
import ForgotPassword from '../../components/Auth/ForgotPassword'

const Auth = () => {
    const [visibleComponent, setVisibleComponent] = useState("SignIn")

    return (
        <div className='auth'>
            {visibleComponent === 'ForgotPassword' &&
                <ForgotPassword setVisibleComponent={setVisibleComponent} />}
            {visibleComponent === 'SignUp' &&
                <SignUp setVisibleComponent={setVisibleComponent} />}
            {visibleComponent === 'SignIn' &&
                <SignIn setVisibleComponent={setVisibleComponent} />}

        </div>
    )
}

export default Auth