export const multiLingColumns = [
    {
        name: 'SR.NO.',
        sortable: true,
        maxWidth: '125px',
        selector: row => row.disbursementId
    },
    {
        name: 'Case Name',
        sortable: true,
        minWidth: '200px',
        selector: row => row.customer_name
    },
    {
        name: 'Loan Amount',
        sortable: true,
        minWidth: '200px',
        selector: row => row.loan_amount_dsa
    },
    {
        name: 'Banker',
        sortable: true,
        minWidth: '100px',
        selector: row => row.bank_name
    },
    {
        name: 'Prouduct',
        sortable: true,
        minWidth: '150px',
        selector: row => row.product
    },

    {
        name: 'Gross Payout',
        sortable: true,
        minWidth: '200px',
        selector: row => row.gross_payout_dsa
    },
    {
        name: 'Action',
        sortable: true,
        minWidth: '150px',
        cell: () => {
            return (
                <span className="action"><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" fillRule="evenodd" d="M20 9c1.1 0 2 .9 2 2v2c0 1.1-.9 2-2 2v3a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v3Zm-2 0V6H4v12h14v-3h-2c-1.1 0-2-1.1-2-2v-1.968C14 9.9 14.9 9 16 9h2Zm-2 4h2v-2h-2v2Z" /></svg></span>
            )
        }
    }
]
