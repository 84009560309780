import React from 'react'

const Header = () => {
    return (
        <nav className='header'>
            <ul>
                <div className="hInfo userInfo">
                    <div className="img">
                        <img src="https://www.w3cplus.com/sites/default/files/blogs/2020/2004/css-circle-image-2.jpg" alt="" />
                    </div>
                    <div className="headerInfo">
                        <h5>Alex Merecer</h5>
                        <p>ERDASDASDERSDFE</p> <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M20 2H10c-1.103 0-2 .897-2 2v4H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2v-4h4c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM4 20V10h10l.002 10H4zm16-6h-4v-4c0-1.103-.897-2-2-2h-4V4h10v10z" /></svg>
                    </div>

                </div>
            </ul>
        </nav>
    )
}

export default Header