import React, { useState } from 'react'
import Header from '../../components/structure/Header'
import Sidebar from '../../components/structure/Sidebar'
import { Card, CardHeader, CardTitle, CardBody, TabContent, TabPane } from 'reactstrap'
import Tab from '../../components/elements/Tab'
import PaymentCompleted from './Payment-Completed'
import PaymentPending from './Payment-Pending'
import ResubmissionCases from './Resubmission-Cases'
const Home = () => {

    const tabs = [
        { id: '1', name: 'Payment History' },
        { id: '2', name: 'Payment Pending' },
        { id: '3', name: 'Re-submission Cases' },
    ]
    const [active, setActive] = useState('1')

    return (
        <>
            <Sidebar />
            <div className="home">
                <Header />

                <div className="container-dashboard">
                    <div className="search">
                        <div className="field" id="searchform">
                            <input type="text" id="searchterm" placeholder="Search here..." />
                            <button type="button" id="search">Search</button>
                        </div>
                    </div>
                    <div className="mt-4">
                        <Card>
                            <CardHeader>
                                <CardTitle tag='h4'>Dashboard</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Tab tabs={tabs} setActive={setActive} activeTab={active}>
                                    <TabContent className='py-50' activeTab={active}>
                                        <TabPane tabId='1'>
                                            <PaymentCompleted />
                                        </TabPane>
                                        <TabPane tabId='2'>
                                            <PaymentPending />
                                        </TabPane>
                                        <TabPane tabId='3'>
                                            <ResubmissionCases />
                                        </TabPane>
                                    </TabContent>
                                </Tab>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home