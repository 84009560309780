import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { multiLingColumns } from './column'
import Table from '../../components/elements/DataTable'
import Sidebar from '../../components/structure/Sidebar'
import Header from '../../components/structure/Header'
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap'
import { fetachAllLoginCodeList, getAllLoginCodeListSelector } from '../../redux/loginCodeList'

const LoginCodeList = () => {
    const [currentPage, setCurrentPage] = useState(1)
    const [searchValue, setSearchValue] = useState('')
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetachAllLoginCodeList({
            allowPagination: false
        }))
    }, [dispatch])

    const allLoginCodeList = useSelector(getAllLoginCodeListSelector)
    const data = allLoginCodeList ? allLoginCodeList : []

    // ** Function to handle filter
    const handlePagination = page => {
        console.log(Number(page))
        setCurrentPage(Number(page))
    }

    // ** Pagination Next Component
    const Next = (page) => {
        if (currentPage !== page) {
            setCurrentPage(Number(currentPage) + 1)
        }
    }

    const handleFilter = e => {
        setSearchValue(e.target.value)

        dispatch(
            fetachAllLoginCodeList({
                allowPagination: false,
                search: e.target.value,
            })
        )
    }

    const dataRender = () => {
        const filters = {
            search: searchValue
        }

        const isFiltered = Object.keys(filters).some(function (k) {
            return filters[k].length > 0
        })

        if (data.length > 0) {
            return data
        } else if (data.length === 0 && isFiltered) {
            return []
        } else {
            return data.slice(0, 25)
        }
    }

    return (
        <> <Sidebar />
            <div className="home">
                <Header />
                <div className="container-dashboard">
                    <div className="search">
                        <div className="field" id="searchform">
                            {/* <Input
                            className='dataTable-filter'
                            type='text'
                            bsSize='sm'
                            id='search-input'
                            placeholder='Search here...'
                            value={searchValue}
                            onChange={handleFilter}
                        /> */}
                            <input type="text" id="searchterm" placeholder="Search here..." value={searchValue} onChange={handleFilter} />
                            <button type="button" id="search">Search</button>
                        </div>
                    </div>
                    <div className="mt-4">
                        <Card>
                            <CardHeader className='kyc-card'>
                                <CardTitle tag='h4'>Login Code List</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Table columns={multiLingColumns} currentPage={currentPage} data={dataRender()} handlePagination={handlePagination} Next={Next} />
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div></>
    )
}

export default LoginCodeList