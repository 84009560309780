import {
    createEntityAdapter,
    createSelector,
    createSlice,
} from '@reduxjs/toolkit';

export const modalAdapater = createEntityAdapter();

export const initialModalState = modalAdapater.getInitialState({
    isDisplay: false,
});

const modalSlice = createSlice({
    name: 'modal',
    initialState: initialModalState,
    reducers: {
        openModal: (state = initialModalState) => {
            state.isDisplay = true;
        },
        closeModal: (state = initialModalState) => {
            state.isDisplay = false;
        },
    },
});

export const modalReducer = modalSlice.reducer;

export const { openModal, closeModal } = modalSlice.actions;

export const geModalState = (rootState) =>
    rootState['modal'];
export const isModalOpen = createSelector(
    geModalState,
    (state) => state.isDisplay
);
