import { createSlice } from "@reduxjs/toolkit"

const userSlice = createSlice({
    name: 'user',
    initialState: {
        user: null
    },
    reducers: {
        login(state, action) { 
            state.user = action.payload
        },
        logout(state) { 
            state.user = null
        },
    },
})

const { actions, reducer } = userSlice

export const { login, logout } = actions

export default reducer
