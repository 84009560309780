import axios from "axios";
import { BASE_URL, API_ROUTES } from '../constants'
import AxiosWrapper from './../services/apiConfig'

const api = axios.create({
    baseURL: BASE_URL
})

const buildParams = (options) => {
    const {
        search,
        sort = 'asc',
        sortBy = 'name',
        allowPagination = false,
        page = 1,
        limit = 10,
        filterParams = ''
    } = options

    const searchParams = []

    if (search) {
        searchParams.push(`customer_name_like==${search}`)
    }

    searchParams.push(`_sort=${sortBy}&_order=${sort}`)

    if (allowPagination) {
        searchParams.push(`_page=${page}&_limit=${limit}`)
    }

    let params = searchParams.join('&')
    filterParams.length > 0 && (params = `${params}&${filterParams}`)

    return params
}

export const getAllUsers = (options) => {
    const {
        search,
        sort = 'asc',
        sortBy = 'id',
        allowPagination = false,
        page = 1,
        limit = 10,
        filterParams = ''
    } = options

    const params = buildParams({
        search,
        sort,
        sortBy,
        allowPagination,
        page,
        limit,
        filterParams
    })

    return api.get(`https://jsonplaceholder.typicode.com/users?${params}`)
        .then((response) => {
            const pageNumber = options.page ? options.page : 1
            return {
                httpCode: response.status,
                message: response.statusText === 'OK' ? 'success' : '',
                payload: response.data,
                total: response.data.length,
                page: pageNumber
            }
        })
        .catch((error) => {
            return {
                httpCode: error.response.status,
                message: error.response.statusText,
                payload: error.response.data,
                total: 0,
                page: 1
            }
        })
}

export const paymentPendingUsers = (options) => {
    const {
        page,
        limit,
        filter = ''
    } = options

    return AxiosWrapper.get({ endpoint: API_ROUTES.PAYMENT_PENDING_USERS, params: { page, limit, filter } })
        .then((response) => {
            return {
                httpCode: response.status,
                message: response.statusText === 'OK' ? 'success' : '',
                payload: response.data,
                total: response.data.length,
                page: page
            }
        })
        .catch((error) => {
            return {
                httpCode: error.response.status,
                message: error.response.statusText,
                payload: error.response.data,
                total: 0,
                page: 1
            }
        })
}

export const reSubmissionUsers = (options) => {
    const {
        page,
        limit,
        filter = ''
    } = options

    return AxiosWrapper.get({ endpoint: API_ROUTES.RE_SUBMISSION_USERS, params: { page, limit, filter } })
        .then((response) => {
            return {
                httpCode: response.status,
                message: response.statusText === 'OK' ? 'success' : '',
                payload: response.data,
                total: response.data.length,
                page: page
            }
        })
        .catch((error) => {
            return {
                httpCode: error.response.status,
                message: error.response.statusText,
                payload: error.response.data,
                total: 0,
                page: 1
            }
        })
}


export const kycDataUpload = (options) => {
    const token = localStorage.getItem('accessToken')
    const headers = {
        'Authorization': `Bearer ${token}`
    }
    return api.post(`${API_ROUTES.KYC_UPLOAD}`, options, { headers })
        .then((response) => {
            return response.data
        })
}

export const getAllUsersReffeted = (options) => {
    const {
        search,
        sortBy = 'id',
        sort = 'asc',
        allowPagination = false,
        page = 1,
        limit = 10,
        filterParams = ''
    } = options

    const params = buildParams({
        search,
        sort,
        sortBy,
        allowPagination,
        page,
        limit,
        filterParams
    })
    const token = localStorage.getItem('token')
    const headers = {
        'Authorization': `Bearer ${token}`
    }
    return api.get(`${API_ROUTES.USERS_REFFERED_ENDPOINT}?${params}`, { headers })
        .then((response) => {
            const pageNumber = options.page ? options.page : 1
            return {
                httpCode: response.status,
                message: response.statusText === 'OK' ? 'success' : '',
                payload: response.data,
                total: response.data.length,
                page: pageNumber
            }
        })
        .catch((error) => {
            return {
                httpCode: error.response.status,
                message: error.response.statusText,
                payload: error.response.data,
                total: 0,
                page: 1
            }
        })
}

export const getAllUsersDisbursement = (options) => {
    console.log(options)
    const {
        page,
        limit,
    } = options

    return AxiosWrapper.get({ endpoint: `${API_ROUTES.USERS_DISBURSEMENT_ENDPOINT}`, page, limit })
        .then((response) => {
            const pageNumber = options.page ? options.page : 1
            return {
                httpCode: response.status,
                message: response.statusText === 'OK' ? 'success' : '',
                payload: response.data,
                total: response.data.length,
                page: pageNumber
            }
        })
        .catch((error) => {
            return {
                httpCode: error.response.status,
                message: error.response.statusText,
                payload: error.response.data,
                total: 0,
                page: 1
            }
        })
}

export const addUserDisbursement = (options) => {
    const token = localStorage.getItem('token')
    const headers = {
        'Authorization': `Bearer ${token}`
    }
    return api.post(`${API_ROUTES.ADD_USER_DISBURSEMENT}`, options, { headers })
        .then((response) => {
            return response.data
        })
}

export const getAllLoginCodeList = (options) => {
    const {
        search,
        sort = 'asc',
        sortBy = 'loginCodeListId',
        allowPagination = false,
        page = 1,
        limit = 10,
        filterParams = ''
    } = options

    const params = buildParams({
        search,
        sort,
        sortBy,
        allowPagination,
        page,
        limit,
        filterParams
    })
    const token = localStorage.getItem('token')
    const headers = {
        'Authorization': `Bearer ${token}`
    }
    return api.get(`${API_ROUTES.LOGIN_CODE_LIST}?${params}`, { headers })
        .then((response) => {
            const pageNumber = options.page ? options.page : 1
            return {
                httpCode: response.status,
                message: response.statusText === 'OK' ? 'success' : '',
                payload: response.data,
                total: response.data.length,
                page: pageNumber
            }
        })
        .catch((error) => {
            return {
                httpCode: error.response.status,
                message: error.response.statusText,
                payload: error.response.data,
                total: 0,
                page: 1
            }
        })
}

export const getAllConnectorList = (options) => {
    const {
        search,
        sort = 'asc',
        sortBy = 'userId',
        allowPagination = false,
        page = 1,
        limit = 10,
        filterParams = ''
    } = options

    const params = buildParams({
        search,
        sort,
        sortBy,
        allowPagination,
        page,
        limit,
        filterParams
    })
    const token = localStorage.getItem('token')
    const headers = {
        'Authorization': `Bearer ${token}`
    }
    return api.get(`${API_ROUTES.CONNECTOR_LIST}?${params}`, { headers })
        .then((response) => {
            const pageNumber = options.page ? options.page : 1
            return {
                httpCode: response.status,
                message: response.statusText === 'OK' ? 'success' : '',
                payload: response.data,
                total: response.data.length,
                page: pageNumber
            }
        })
        .catch((error) => {
            return {
                httpCode: error.response.status,
                message: error.response.statusText,
                payload: error.response.data,
                total: 0,
                page: 1
            }
        })
}

export const addConnector = (options) => {
    const token = localStorage.getItem('token')
    const headers = {
        'Authorization': `Bearer ${token}`
    }
    return api.post(`${API_ROUTES.ADD_CONNECTOR}`, options, { headers })
        .then((response) => {
            return response.data
        })
}


export const getAllSMList = (options) => {
    const {
        page,
        limit,
    } = options

    return AxiosWrapper.get({ endpoint: `${API_ROUTES.SM_LIST}`, page, limit })
        .then((response) => {
            const pageNumber = options.page ? options.page : 1
            return {
                httpCode: response.status,
                message: response.statusText === 'OK' ? 'success' : '',
                payload: response.data,
                total: response.data.length,
                page: pageNumber
            }
        })
        .catch((error) => {
            return {
                httpCode: error.response.status,
                message: error.response.statusText,
                payload: error.response.data,
                total: 0,
                page: 1
            }
        })
}

export const getAllPayoutGirdList = (options) => {
    const {
        search,
        sort = 'asc',
        allowPagination = false,
        page = 1,
        limit = 10,
        filterParams = ''
    } = options

    const params = buildParams({
        search,
        sort,
        allowPagination,
        page,
        limit,
        filterParams
    })
    const token = localStorage.getItem('token')
    const headers = {
        'Authorization': `Bearer ${token}`
    }
    console.log(`${API_ROUTES.PAYOUT_GRID_LIST}?${params}`, 'fsdfds')
    return api.get(`${API_ROUTES.PAYOUT_GRID_LIST}?${params}`, { headers })
        .then((response) => {
            const pageNumber = options.page ? options.page : 1
            return {
                httpCode: response.status,
                message: response.statusText === 'OK' ? 'success' : '',
                payload: response.data,
                total: response.data.length,
                page: pageNumber
            }
        })
        .catch((error) => {
            return {
                httpCode: error.response.status,
                message: error.response.statusText,
                payload: error.response.data,
                total: 0,
                page: 1
            }
        })
}

export const getOnePayoutGird = (id) => {
    const token = localStorage.getItem('token')
    const headers = {
        'Authorization': `Bearer ${token}`
    }
    return api.get(`${API_ROUTES.NORMAL_ADVANCED_GRID}/${id}?`, { headers })
        .then((response) => {
            return {
                httpCode: response.status,
                message: response.statusText === 'OK' ? 'success' : '',
                payload: response.data
            }
        })
        .catch((error) => {
            return {
                httpCode: error.response.status,
                message: error.response.statusText,
                payload: error.response.data,

            }
        })
}

export const updatePayoutGird = (data) => {

    return AxiosWrapper.post({ endpoint: `${API_ROUTES.NORMAL_ADVANCED_GRID}/${data.login_code_list_id}`, data })
        .then((response) => {
            return {
                httpCode: response.status,
                message: response.statusText === 'OK' ? 'success' : '',
                payload: response.data
            }
        })
        .catch((error) => {
            return {
                httpCode: error.response.status,
                message: error.response.statusText,
                payload: error.response.data,

            }
        })
}