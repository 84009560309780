// ** React Imports
import { Fragment } from 'react'

// ** Reactstrap Imports
import { Nav, NavItem, NavLink } from 'reactstrap'

const Tab = (props) => {
    const { tabs = [], activeTab = 0, setActive } = props
    const toggle = tab => {
        if (activeTab !== tab) {
            setActive(tab)
        }
    }
    return (
        <Fragment>
            <Nav tabs>
                {tabs.map((tab, index) => (
                    <NavItem key={index}>
                        <NavLink
                            className={`${activeTab === tab.id ? 'active' : ''}`}
                            onClick={() => {
                                toggle(tab.id)
                            }}
                        >
                            {tab.name}
                        </NavLink>
                    </NavItem>
                ))}
            </Nav>
            {props.children}

        </Fragment>
    )
}
export default Tab