import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../constants'
import Input from '../Input'

function SignUp({ setVisibleComponent }) {
    const [name, setName] = useState("")
    const [firmName, setFirmName] = useState("")
    const [inviteCode, setInviteCode] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [mobileNumber, setMobileNumber] = useState("")
    const [panNo, setPanNo] = useState("")
    const [city, setCity] = useState("")
    const [state, setState] = useState("")
    const [postalCode, setPostalCode] = useState("")

    const [errorMessage, setErrorMessage] = useState({
        name: 'Please enter valid name',
        firmName: 'Please enter valid firm name',
        inviteCode: 'Please enter valid invite code',
        email: 'Please enter valid email',
        password: 'Please enter valid password',
        confirmPassword: 'Please enter valid confirm password',
        mobileNumber: 'Please enter valid mobile number',
        panNo: 'Please enter valid pan no',
        postalCode: 'Please enter valid postal code'
    })
    const [isErrorVisible, setIsErrorVisible] = useState(false)

    useEffect(() => {
        if (name.length)
            setErrorMessage({ ...errorMessage, name: '' })
        else
            setErrorMessage({ ...errorMessage, name: 'Please enter valid name' })
    }, [name])
    useEffect(() => {
        if (firmName.length)
            setErrorMessage({ ...errorMessage, firmName: '' })
        else
            setErrorMessage({ ...errorMessage, firmName: 'Please enter valid firm name' })
    }, [firmName])
    useEffect(() => {
        if (inviteCode.length)
            setErrorMessage({ ...errorMessage, inviteCode: '' })
        else
            setErrorMessage({ ...errorMessage, inviteCode: 'Please enter valid invite code' })
    }, [inviteCode])
    useEffect(() => {
        if (confirmPassword.length && confirmPassword === password)
            setErrorMessage({ ...errorMessage, confirmPassword: '' })
        else
            setErrorMessage({ ...errorMessage, confirmPassword: `Confirm password doesn't match` })
    }, [confirmPassword, password])
    useEffect(() => {
        if (mobileNumber.length)
            setErrorMessage({ ...errorMessage, mobileNumber: '' })
        else
            setErrorMessage({ ...errorMessage, mobileNumber: 'Please enter valid mobile number' })
    }, [mobileNumber])
    useEffect(() => {
        if (panNo.length)
            setErrorMessage({ ...errorMessage, panNo: '' })
        else
            setErrorMessage({ ...errorMessage, panNo: 'Please enter valid pan no' })
    }, [panNo])
    useEffect(() => {
        if (postalCode.length === 6) {
            fetchPostalData()
            setErrorMessage({ ...errorMessage, postalCode: '' })
        }
        else {
            setCity("")
            setState("")
            setErrorMessage({ ...errorMessage, postalCode: 'Please enter valid postal code' })
        }
    }, [postalCode])
    useEffect(() => {
        if (mobileNumber.length)
            setErrorMessage({ ...errorMessage, mobileNumber: '' })
        else
            setErrorMessage({ ...errorMessage, mobileNumber: 'Please enter valid mobile number' })
    }, [mobileNumber])
    useEffect(() => {
        const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        if (email.length && regex.test(email))
            setErrorMessage({ ...errorMessage, email: '' })
        else
            setErrorMessage({ ...errorMessage, email: 'Please enter valid email' })
    }, [email])
    useEffect(() => {
        const regex = /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/
        if (password.length && regex.test(password))
            setErrorMessage({ ...errorMessage, password: '' })
        else
            setErrorMessage({ ...errorMessage, password: 'Please enter valid password' })
    }, [password])

    const fetchPostalData = async () => {
        try {
            const response = await axios.get(`https://api.postalpincode.in/pincode/${postalCode}`)
            if (response.data && response.data[0].Status === 'Success') {
                setCity(response.data[0].PostOffice[0].District)
                setState(response.data[0].PostOffice[0].State)
            }
            else {
                setCity("")
                setState("")
            }
            // console.log(JSON.stringify(response))
        }
        catch (error) {
            console.error(error)
        }
    }

    const signUp = async () => {
        setIsErrorVisible(true)
        console.log('called');
        // if (errorMessage.name || errorMessage.firmName || errorMessage.inviteCode || errorMessage.email || errorMessage.password || errorMessage.confirmPassword || errorMessage.mobileNumber || errorMessage.panNo || errorMessage.postalCode) return

        try {
            console.log('called >> ');
            const response = await axios.post(
                `${BASE_URL}user/insert`,
                {
                    "name": name,
                    "email": email,
                    "password": password,
                    "city": city,
                    "state": state,
                    "contact": mobileNumber,
                    "pin": postalCode,
                    "pan_no": panNo,
                    "invite_code": inviteCode,
                })
            console.log(`signUp ${response}`);
        }
        catch (error) {
            console.error(`signUp ${error}`);
        }
    }

    return (
        <div className="form form-sign-up">
            <div className="headers">
                <div className="title">Sign up</div>
                <div><a href="" className="close-modal">&times;</a></div>
            </div>
            <hr />
            <div className="flex-container">
                <div>
                    <Input
                        id="name"
                        type="text"
                        placeholder='Name'
                        value={name}
                        onChange={setName}
                        icon={<img src={require("./../../assets/images/Combined-Shapename.png")} alt="" />} />
                    {isErrorVisible && <p className='error-text-msg'>{errorMessage.name}</p>}
                </div>
                <div>
                    <Input
                        id="firmName"
                        type="text"
                        placeholder='First name'
                        value={firmName}
                        onChange={setFirmName}
                        icon={<img src={require("./../../assets/images/Combined-Shapename.png")} alt="" />} />
                    {isErrorVisible && <p className='error-text-msg'>{errorMessage.firmName}</p>}
                </div>
            </div>
            <div className="flex-container">
                <div>
                    <Input
                        id="inviteCode"
                        type="text"
                        placeholder='Invite code'
                        value={inviteCode}
                        onChange={setInviteCode}
                        icon={<img src={require("./../../assets/images/Combined-Shapename.png")} alt="" />} />
                    {isErrorVisible && <p className='error-text-msg'>{errorMessage.inviteCode}</p>}
                </div>
                <div>
                    <Input
                        id="email"
                        type="email"
                        placeholder='Email'
                        value={email}
                        onChange={setEmail}
                        icon={<img src={require("./../../assets/images/messageemail.png")} alt="" />} />
                    {isErrorVisible && <p className='error-text-msg'>{errorMessage.email}</p>}
                </div>
            </div>
            <div className="flex-container">
                <div>
                    <Input
                        id="password"
                        type="password"
                        placeholder='Password'
                        value={password}
                        onChange={setPassword}
                        icon={<img src={require("./../../assets/images/password-password.png")} alt="" />} />
                    {isErrorVisible && <p className='error-text-msg'>{errorMessage.password}</p>}
                </div>
                <div>
                    <Input
                        id="confirmPassword"
                        type="password"
                        placeholder='Confirm Password'
                        value={confirmPassword}
                        onChange={setConfirmPassword}
                        icon={<img src={require("./../../assets/images/password-password.png")} alt="" />} />
                    {isErrorVisible && <p className='error-text-msg'>{errorMessage.confirmPassword}</p>}
                </div>
            </div>
            <div className="flex-container">
                <div>
                    <Input
                        id="mobileNumber"
                        type="number"
                        placeholder='Mobile Number'
                        value={mobileNumber}
                        onChange={setMobileNumber}
                        icon={<img src={require("./../../assets/images/password-password.png")} alt="" />} />
                    {isErrorVisible && <p className='error-text-msg'>{errorMessage.mobileNumber}</p>}
                </div>
                <div>
                    <Input
                        id="panNo"
                        type="text"
                        placeholder='PAN No.'
                        value={panNo}
                        onChange={setPanNo}
                        icon={<img src={require("./../../assets/images/Fill 1call.png")} alt="" />} />
                    {isErrorVisible && <p className='error-text-msg'>{errorMessage.panNo}</p>}
                </div>
            </div>
            <div className="flex-container">
                <div>
                    <Input
                        id="state"
                        type="text"
                        placeholder='State'
                        value={state}
                        disabled={true}
                        onChange={setState}
                        icon={<img src={require("./../../assets/images/Fill 1call.png")} alt="" />} />
                </div>
                <div>
                    <Input
                        id="city"
                        type="text"
                        placeholder='City'
                        value={city}
                        disabled={true}
                        onChange={setCity}
                        icon={<img src={require("./../../assets/images/Fill 1call.png")} alt="" />} />
                </div>
            </div>
            <div className="flex-container custom-width">
                <div>
                    <Input
                        id="postalCode"
                        type="text"
                        placeholder='Postal Code'
                        maxLength={6}
                        value={postalCode}
                        onChange={setPostalCode}
                        icon={<img src={require("./../../assets/images/Unionlocation.png")} alt="" />} />
                    {isErrorVisible && <p className='error-text-msg'>{errorMessage.postalCode}</p>}
                </div>
            </div>
            <div className="privacy-policy">
                <input type="checkbox" id="chkRemember" name="Remember" value="Remember me" />
                <label htmlFor="Remember" className="sub-text ml-4">I agree with <b className="text-sky-blue">Privacy Policy</b></label><br />
            </div>
            <button onClick={(e) => {
                e.preventDefault()
                signUp()
            }} type="text" className="signup">Sign up</button>
            <div className="form-footer">
                <div className="sub-text">Already have an account? <a onClick={() => setVisibleComponent("SignIn")} className="sign-up"><b> Sign in</b></a>
                </div>
            </div>
        </div>
    )
}

export default SignUp