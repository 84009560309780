export const multiLingColumns = [
    {
        name: 'SR.NO.',
        sortable: true,
        maxWidth: '125px',
        selector: row => row.disbursementId
    },
    {
        name: 'Borrower Name',
        sortable: true,
        minWidth: '200px',
        selector: row => row.customer_name
    },
    {
        name: 'Loan Amount',
        sortable: true,
        minWidth: '200px',
        selector: row => row.loan_amount_dsa
    },
    {
        name: 'Financer',
        sortable: true,
        minWidth: '100px',
        selector: row => row.financer ? row.financer : 'John Bravo'
    },
    {
        name: 'Product',
        sortable: true,
        minWidth: '200px',
        selector: row => row.product
    },

    {
        name: 'Dis. Month',
        sortable: true,
        minWidth: '100px',
        selector: row => row.month ? row.month : 'January'
    },
    {
        name: 'Connector Name',
        sortable: true,
        minWidth: '200px',
        selector: row => row.connecter_name ? row.connecter_name : 'Alex',
    },
    {
        name: 'Gross Payout',
        sortable: true,
        minWidth: '100px',
        selector: row => row.gross_payout_dsa
    },
]
