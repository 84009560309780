import React from 'react'
// ** Reactstrap Imports
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input } from 'reactstrap'

const Modals = (props) => {
    const { title, openModal, setOpenModal } = props
    // const [modal, setModal] = useState(false)
    return (
        <div>

            <Modal isOpen={openModal} toggle={() => setOpenModal(!openModal)} className='modal-dialog-centered modal-lg'>
                <ModalHeader toggle={() => setOpenModal(!openModal)}>{title}</ModalHeader>
                {props.children}


            </Modal>
        </div>
    )
}

export default Modals