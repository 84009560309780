import {
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
    createSlice
} from '@reduxjs/toolkit'
import { getAllSMList } from '../api'

export const smListAdapater = createEntityAdapter()

export const fetachAllSMList = createAsyncThunk('SMList/fetch', async (options) => {
    const response = await getAllSMList(options)
    return response
})

const smState = {
    page: 1,
    total: 0,
    isLoading: false,
    error: null,
    success: false,
    action: '',
    allSMList: []
}

export const initialSMList = {
    all: smState,
    operations: smState,
    view: smState,
}

export const smListSlice = createSlice({
    name: 'smList',
    initialState: initialSMList,
    reducers: {
        add: smListAdapater.addOne,
        remove: smListAdapater.removeOne
    },
    extraReducers: (builder) => {
        builder.addCase(fetachAllSMList.pending, (state, action) => {
            state.all.isLoading = true
            state.all.error = null
            state.all.success = false
            state.all.action = 'fetch'
        })
        builder.addCase(fetachAllSMList.fulfilled, (state, action) => {
            state.all.isLoading = false
            state.all.error = null
            state.all.success = true
            state.all.action = 'fetch'
            state.all.allSMList = action.payload.payload
            state.all.total = action.payload.total
            state.all.page = action.payload.page
        })
        builder.addCase(fetachAllSMList.rejected, (state, action) => {
            state.all.isLoading = false
            state.all.error = action.payload
            state.all.success = false
            state.all.action = 'fetch'
        })
    }
})

export const smListReducer = smListSlice.reducer
export const smListActions = smListSlice.actions

export const geSMListState = (rootState) => rootState[smListSlice.name]

export const getAllSMListSelector = createSelector(
    geSMListState,
    (state) => state.all.allSMList
)