import {
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
    createSlice
} from '@reduxjs/toolkit'
import { getAllUsersReffeted } from '../api'

export const userRefferedAdpater = createEntityAdapter()

export const fetachAllUserReffered = createAsyncThunk('users/reffered/fetch', async (options) => {
    const response = await getAllUsersReffeted(options)
    return response
})

const userRefferdState = {
    page: 1,
    total: 0,
    isLoading: false,
    error: null,
    success: false,
    action: '',
    allUserReffered: []
}

export const initialUserRefferdState = {
    all: userRefferdState,
    operations: userRefferdState,
    view: userRefferdState,
}

export const userRefferdSlice = createSlice({
    name: 'userRefferd',
    initialState: initialUserRefferdState,
    reducers: {
        add: userRefferedAdpater.addOne,
        remove: userRefferedAdpater.removeOne
    },
    extraReducers: (builder) => {
        builder.addCase(fetachAllUserReffered.pending, (state, action) => {
            state.all.isLoading = true
            state.all.error = null
            state.all.success = false
            state.all.action = 'fetch'
        })
        builder.addCase(fetachAllUserReffered.fulfilled, (state, action) => {
            state.all.isLoading = false
            state.all.error = null
            state.all.success = true
            state.all.action = 'fetch'
            state.all.allUserReffered = action.payload.payload
            state.all.total = action.payload.total
            state.all.page = action.payload.page
        })
        builder.addCase(fetachAllUserReffered.rejected, (state, action) => {
            state.all.isLoading = false
            state.all.error = action.payload
            state.all.success = false
            state.all.action = 'fetch'
        })
    }
})

export const userRefferdReducer = userRefferdSlice.reducer
export const userRefferdActions = userRefferdSlice.actions

export const getUserRefferdState = (rootState) => rootState[userRefferdSlice.name]

export const getAllUserReffered = createSelector(
    getUserRefferdState,
    (state) => state.all.allUserReffered
)