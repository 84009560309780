import React, { useState, useEffect } from 'react'
import Table from '../../../components/elements/DataTable'
import { multiLingColumn } from './column'


const L2Down = () => {
    const [currentPage, setCurrentPage] = useState(1)
    const allUsers = [
        {
            id: 1,
            name: 'John Doe',
            city: 'Rajkot',
            state: 'Gujarat',
            contactNo: '9876543210',
            totalBiLing: '100',
        },
        {
            id: 2,
            name: 'John Doe',
            city: 'Rajkot',
            state: 'Gujarat',
            contactNo: '9876543210',
            totalBiLing: '100',
        },
        {
            id: 3,
            name: 'John Doe',
            city: 'Rajkot',
            state: 'Gujarat',
            contactNo: '9876543210',
            totalBiLing: '100',
        },
        {
            id: 4,
            name: 'John Doe',
            city: 'Rajkot',
            state: 'Gujarat',
            contactNo: '9876543210',
            totalBiLing: '100',
        },
        {
            id: 5,
            name: 'John Doe',
            city: 'Rajkot',
            state: 'Gujarat',
            contactNo: '9876543210',
            totalBiLing: '100',
        },
        {
            id: 6,
            name: 'John Doe',
            city: 'Rajkot',
            state: 'Gujarat',
            contactNo: '9876543210',
            totalBiLing: '100',
        },
        {
            id: 7,
            name: 'John Doe',
            city: 'Rajkot',
            state: 'Gujarat',
            contactNo: '9876543210',
            totalBiLing: '100',
        },
        {
            id: 8,
            name: 'John Doe',
            city: 'Rajkot',
            state: 'Gujarat',
            contactNo: '9876543210',
            totalBiLing: '100',
        },
        {
            id: 9,
            name: 'John Doe',
            city: 'Rajkot',
            state: 'Gujarat',
            contactNo: '9876543210',
            totalBiLing: '100',
        },
        {
            id: 10,
            name: 'John Doe',
            city: 'Rajkot',
            state: 'Gujarat',
            contactNo: '9876543210',
            totalBiLing: '100',
        },
    ]
    // ** Function to handle filter
    const handlePagination = page => {
        console.log(Number(page))
        setCurrentPage(Number(page))
    }

    // ** Pagination Next Component
    const Next = (page) => {
        if (currentPage !== page) {
            setCurrentPage(Number(currentPage) + 1)
        }
    }
    return (
        <Table columns={multiLingColumn} currentPage={currentPage} data={Array.isArray(allUsers) ? allUsers : []} handlePagination={handlePagination} Next={Next} />
    )
}

export default L2Down