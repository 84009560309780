import React from 'react'
import 'react-toastify/dist/ReactToastify.css';
import { Route, BrowserRouter, Switch } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import Auth from './../pages/Auth/Auth'
import Home from '../pages/Home/Home'
import ProtectedRoute from './ProtectedRoute'
import PageNotFound from '../pages/PageNotFound/PageNotFound'
import KYC from '../pages/KYC'
import PendingReview from '../pages/Pending-Review'
import UserReffered from '../pages/User-Reffered'
import Disbursement from '../pages/Disbursement'
import LoginCodeList from '../pages/Login-Code-List'
import ConnectorList from '../pages/Connector-List'
import SMList from '../pages/SM-List'
import PayoutGrid from '../pages/Payout-Grid'

const Navigation = () => {
    return (
        <BrowserRouter>
            <Switch>
                {/* <ProtectedRoute path='/home' exact>
                    <Home />
                </ProtectedRoute>
                <ProtectedRoute exact path='/kyc'>
                    <KYC />
                </ProtectedRoute>
                <ProtectedRoute path='/pending-review' exact>
                    <PendingReview />
                </ProtectedRoute>
                <ProtectedRoute path='/user-reffered' exact>
                    <UserReffered />
                </ProtectedRoute>
                <ProtectedRoute path='/disbursement' exact>
                    <Disbursement />
                </ProtectedRoute>
                <ProtectedRoute path='/login-code' exact>
                    <LoginCodeList />
                </ProtectedRoute>
                <ProtectedRoute path='/connector-list' exact>
                    <ConnectorList />
                </ProtectedRoute>
                <ProtectedRoute path='/sm-list' exact>
                    <SMList />
                </ProtectedRoute>
                <ProtectedRoute path='/payout-gird' exact>
                    <PayoutGrid />
                </ProtectedRoute> */}

                <Route path='/home' exact>
                    <Home />
                </Route>
                <Route exact path='/kyc'>
                    <KYC />
                </Route>
                <Route path='/pending-review' exact>
                    <PendingReview />
                </Route>
                <Route path='/user-reffered' exact>
                    <UserReffered />
                </Route>
                <Route path='/disbursement' exact>
                    <Disbursement />
                </Route>
                <Route path='/login-code' exact>
                    <LoginCodeList />
                </Route>
                <Route path='/connector-list' exact>
                    <ConnectorList />
                </Route>
                <Route path='/sm-list' exact>
                    <SMList />
                </Route>
                <Route path='/payout-gird' exact>
                    <PayoutGrid />
                </Route>

                {/* <AuthRoute path='/' exact>
                    <Auth />
                </AuthRoute> */}
                <Route path="/" component={Auth} />
                <Route path="*" component={PageNotFound} />
            </Switch>
            <ToastContainer newestOnTop autoClose={3000} />
        </BrowserRouter>)
}

export default Navigation;