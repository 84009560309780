import React from 'react'

function Input({ id = "", type = "text", placeholder = "", disabled = false, icon, value, onChange, ...props }) {
    return (
        <div className="input-container ic2">
            <input id={id} type={type} className="input" disabled={disabled} value={value} onChange={(e) => onChange(e.target.value)} {...props} />
            {icon}
            <label htmlFor={id} className="placeholders">{placeholder}</label>
        </div>
    )
}

export default Input