import {
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
    createSlice
} from '@reduxjs/toolkit'
import { getAllConnectorList, addConnector } from '../api'

export const connectorListAdapter = createEntityAdapter()

export const fetachConnectorList = createAsyncThunk('users/connectorList/fetch', async (options) => {
    const response = await getAllConnectorList(options)
    return response
})

export const addConnectorData = createAsyncThunk('users/connectorList/add', async (options) => {
    const response = await addConnector(options)
    return response
})

const connectorListState = {
    page: 1,
    total: 0,
    isLoading: false,
    error: null,
    success: false,
    action: '',
    allConnectorList: []
}

export const initialConnectorList = {
    all: connectorListState,
    operations: connectorListState,
    view: connectorListState,
}

export const connectorSlice = createSlice({
    name: 'connectorList',
    initialState: initialConnectorList,
    reducers: {
        add: connectorListAdapter.addOne,
        remove: connectorListAdapter.removeOne
    },
    extraReducers: (builder) => {
        builder.addCase(fetachConnectorList.pending, (state, action) => {
            state.all.isLoading = true
            state.all.error = null
            state.all.success = false
            state.all.action = 'fetch'
        })
        builder.addCase(fetachConnectorList.fulfilled, (state, action) => {
            state.all.isLoading = false
            state.all.error = null
            state.all.success = true
            state.all.action = 'fetch'
            state.all.allConnectorList = action.payload.payload
            state.all.total = action.payload.total
            state.all.page = action.payload.page
        })
        builder.addCase(fetachConnectorList.rejected, (state, action) => {
            state.all.isLoading = false
            state.all.error = action.payload
            state.all.success = false
            state.all.action = 'fetch'
        })
        builder.addCase(addConnectorData.pending, (state, action) => {
            state.all.isLoading = true
            state.all.error = null
            state.all.success = false
            state.all.action = 'add'
        })
        builder.addCase(addConnectorData.fulfilled, (state, action) => {
            state.all.isLoading = false
            state.all.error = null
            state.all.success = true
            state.all.action = 'add'
        })
        builder.addCase(addConnectorData.rejected, (state, action) => {
            state.all.isLoading = false
            state.all.error = action.payload
            state.all.success = false
            state.all.action = 'add'
        })
    }
})

export const connectorListReducer = connectorSlice.reducer
export const connectorActions = connectorSlice.actions

export const getConnectorListState = (rootState) => rootState[connectorSlice.name]

export const getAllConnectorListSelector = createSelector(
    getConnectorListState,
    (state) => state.all.allConnectorList
)