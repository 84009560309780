import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import { Briefcase, Calendar, MapPin, Phone, User, MoreVertical, Edit, FileText, Archive, Trash, Edit3, PlusSquare } from 'react-feather'
// import { multiLingColumns } from './column'
import { SlideDown } from 'react-slidedown'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import Tables from '../../components/elements/DataTable'
import Sidebar from '../../components/structure/Sidebar'
import Header from '../../components/structure/Header'
import Select from 'react-select'
import Modals from '../../components/elements/Modal'
import Repeater from '../../components/elements/Repeater'
import classnames from 'classnames'
import { Card, CardHeader, CardTitle, CardBody, Row, Col, Input, Button, Form, ModalBody, ModalFooter, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Table, Label, FormFeedback } from 'reactstrap'
import { fetachPayoutGridList, fetchOnePayoutGrid, getAllPayoutGridListSelector, getOnePayoutGirdSelector, updateOnePayoutGrid } from '../../redux/payoutGrid'
import { Slide, toast } from 'react-toastify'
import { ErrorToast, SuccessToast } from '../../components/elements/Toastify'

const colourOptions = [
    { value: 'a', label: 'A' },
    { value: 'b', label: 'B' },
    { value: 'c', label: 'C' },
    { value: 'd', label: 'D' },
    { value: 'e', label: 'E' }
]

const PayoutGrid = () => {
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [openModal, setOpenModal] = useState(false)
    const [openTCModal, setTCOpenModal] = useState(false)
    const dispatch = useDispatch()

    const Schema = yup.object().shape({
        disbursal_amount: yup.string().required('Disbursal Amount is required'),
        normal_payout_grid: yup.mixed().required('Normal Payout Grid is required'),
        advanced_payout_grid: yup.mixed().required('Advanced Payout Grid is required'),
    })

    const allUsers = [
        {
            "id": "1",
            "name": "John Doe",
            "product": "Product 1",
            "normal_payout_slab": "1%",
            advance_payout_slab: "2%",
        }
    ]

    useEffect(() => {
        dispatch(fetachPayoutGridList({
            allowPagination: false
        }))
    }, [dispatch])

    const payoutGirdList = useSelector(getAllPayoutGridListSelector)
    const payoutGirdDetail = useSelector(getOnePayoutGirdSelector)
    console.log('payoutGirdDetail', payoutGirdDetail)


    const editModal = (data) => {
        dispatch(fetchOnePayoutGrid(data.loginCodeListId)).then(res => {
            setOpenModal(!openModal)
        })

    }

    const multiLingColumns = [
        {
            name: 'SR.NO.',
            sortable: true,
            maxWidth: '125px',
            selector: row => row.loginCodeListId
        },
        {
            name: 'Name',
            sortable: true,
            minWidth: '200px',
            selector: row => row.dsa_name
        },
        {
            name: 'Product',
            sortable: true,
            minWidth: '200px',
            selector: row => row.product
        },
        {
            name: 'Normal Payout Slab',
            sortable: true,
            minWidth: '100px',
            selector: row => row.normal_payout_slab,
            cell: (row) => {
                return (
                    <>
                        <span className='normal_payout_slab'>{row.normal_payout_slab || '1%'}</span><PlusSquare className='svg-pointer' />
                    </>
                )
            }
        },
        {
            name: 'Advance Payout Slab',
            sortable: true,
            minWidth: '150px',
            selector: row => row.advance_payout_slab,
            cell: (row) => {
                return (
                    <>
                        <span className='normal_payout_slab'>{row.advance_payout_slab || '2%'}</span><PlusSquare className='svg-pointer' />
                    </>
                )
            }
        },
        {
            name: 'Actions',
            allowOverflow: true,
            cell: (row) => {
                return (
                    <div className='d-flex'>
                        <UncontrolledDropdown>
                            <DropdownToggle className='pe-1' tag='span'>
                                <MoreVertical size={15} />
                            </DropdownToggle>
                            <DropdownMenu end>
                                <DropdownItem onClick={() => editModal(row)}>
                                    <Edit3 size={15} />
                                    <span className='align-middle ms-50'>Edit</span>
                                </DropdownItem>
                                <DropdownItem onClick={() => setTCOpenModal(!openTCModal)}>
                                    <PlusSquare size={15} />
                                    <span className='align-middle ms-50'>Add T&C</span>
                                </DropdownItem>
                                <DropdownItem >
                                    <Trash size={15} style={{ stroke: "red" }} />
                                    <span className='align-middle ms-50' style={{ color: "red" }}>Delete</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                )
            }
        }
    ]



    const {
        reset,
        control,
        handleSubmit,
        formState: { errors }
    } = useForm({ mode: 'onChange', resolver: yupResolver(Schema) })

    // ** Function to handle filter
    const handlePagination = page => {
        console.log(Number(page))
        setCurrentPage(Number(page))
    }

    // ** Pagination Next Component
    const Next = (page) => {
        if (currentPage !== page) {
            setCurrentPage(Number(currentPage) + 1)
        }
    }

    const increaseCount = () => {
        setCount(count + 1)
    }
    const onSubmit = data => {
        const payload = {
            ...data,
            product: 'Product 1',
            login_code_list_id: payoutGirdDetail.login_code_list_id
        }
        dispatch(updateOnePayoutGrid(payload)).then(res => {
            console.log('res', res)
            if (res.payload.status === 200) {
                toast.success(<SuccessToast title="Success!" text="Payout-Grid Updated Successfully" />, { icon: false, hideProgressBar: true, transition: Slide })
                dispatch(fetachPayoutGridList({
                    allowPagination: false
                }))
                reset()
                setOpenModal(!openModal)
            }
        }
        ).catch(err => {
            console.log(err)
            reset()
            toast.error(<ErrorToast title="Error!" text={err.message} />, { icon: false, hideProgressBar: true, transition: Slide })
            setOpenModal(!openModal)
        })
    }

    return (
        <>
            <Sidebar />
            <div className="home">
                <Header />
                <div className="container-dashboard">
                    <div className="search">
                        <div className="field" id="searchform">
                            <input type="text" id="searchterm" placeholder="Search here..." />
                            <button type="button" id="search">Search</button>
                        </div>
                    </div>
                    <div className="mt-4">
                        <Card>
                            <CardHeader className='kyc-card'>
                                <CardTitle tag='h4'>Pending for Review</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Tables columns={multiLingColumns} currentPage={currentPage} data={payoutGirdList} handlePagination={handlePagination} Next={Next} />
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>

            {/* Edit Payout Gird */}
            <Form>
                <Modals openModal={openModal} setOpenModal={setOpenModal} title='Edit Payout Gird' >
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <ModalBody>
                            <Row>
                                <Col md='6' sm='12' className='mb-4'>
                                    <Controller
                                        id='flatSlab'
                                        control={control}
                                        name='flatSlab'
                                        render={({ field }) => (
                                            <Select
                                                isClearable={true}
                                                options={colourOptions}
                                                classNamePrefix='select'
                                                placeholder='Fla Slab'
                                                // theme={selectThemeColors}
                                                // className={classnames('react-select', { 'is-invalid': datas !== null && datas.selectInterviwer === null })}
                                                className={classnames('react-select', { 'is-invalid': errors.flatSlab && true })}
                                                {...field}
                                            />
                                        )}
                                    />
                                </Col>
                                <Col md='6' sm='12' className='mb-4'>
                                    <Controller
                                        id='businessLoan'
                                        control={control}
                                        name='businessLoan'
                                        render={({ field }) => (
                                            <Select
                                                isClearable={true}
                                                options={colourOptions}
                                                classNamePrefix='select'
                                                placeholder='Business Loan'
                                                // theme={selectThemeColors}
                                                // className={classnames('react-select', { 'is-invalid': datas !== null && datas.selectInterviwer === null })}
                                                className={classnames('react-select', { 'is-invalid': errors.businessLoan && true })}
                                                {...field}
                                            />
                                        )}
                                    />
                                </Col>
                                <Col md='12'>
                                    <div className="tb">
                                        <div className="tb-heading">
                                            <h5>Disbursal Amount</h5>
                                            <h5>Normal Payout Grid</h5>
                                            <h5>Advance Payout Grid</h5>
                                        </div>
                                        <div className="tb-details">
                                            <div className="tb-details-row">
                                                <div className="tb-details-col">
                                                    <Controller
                                                        id='disbursal_amount'
                                                        name='disbursal_amount'
                                                        defaultValue={payoutGirdDetail ? payoutGirdDetail.disbursal_amount : ''}
                                                        control={control}
                                                        render={({ field }) => <Input placeholder='Disbursal Amount' invalid={errors.disbursal_amount && true} {...field} />}
                                                    />
                                                    {errors.disbursal_amount ? <FormFeedback>{errors.disbursal_amount.message}</FormFeedback> : ''}
                                                </div>
                                                <div className="tb-details-col">
                                                    <Controller
                                                        id='normal_payout_grid'
                                                        name='normal_payout_grid'
                                                        defaultValue={payoutGirdDetail ? `${payoutGirdDetail.normal_payout_grid} %` : ''}
                                                        control={control}
                                                        render={({ field }) => <Input placeholder='Normal Payout Grid' invalid={errors.normal_payout_grid && true} {...field} />}
                                                    />
                                                    {errors.normal_payout_grid ? <FormFeedback>{errors.normal_payout_grid.message}</FormFeedback> : ''}
                                                </div>
                                                <div className="tb-details-col">
                                                    <Controller
                                                        id='advanced_payout_grid'
                                                        name='advanced_payout_grid'
                                                        defaultValue={payoutGirdDetail ? `${payoutGirdDetail.advanced_payout_grid} %` : ''}
                                                        control={control}
                                                        render={({ field }) => <Input placeholder='Advance Payout Grid' invalid={errors.advanced_payout_grid && true} {...field} />}
                                                    />
                                                    {errors.advanced_payout_grid ? <FormFeedback>{errors.advanced_payout_grid.message}</FormFeedback> : ''}
                                                </div>
                                            </div>
                                            {/* <Repeater count={count}>
                                            {i => {
                                                const Tag = count === 0 ? 'div' : SlideDown
                                                return (
                                                    <Tag key={i}>
                                                        <Form>
                                                            <div className="tb-details-row">
                                                                <div className="tb-details-col">
                                                                    <input type="text" className="form-control" placeholder="Disbursal Amount" />
                                                                </div>
                                                                <div className="tb-details-col">
                                                                    <input type="text" className="form-control" placeholder="Normal Payout Grid" />
                                                                </div>
                                                                <div className="tb-details-col">
                                                                    <input type="text" className="form-control" placeholder="Advance Payout Grid" />
                                                                </div>
                                                            </div>
                                                        </Form>
                                                    </Tag>
                                                )
                                            }}
                                        </Repeater> */}
                                            <div className="tb-details-row-btn">
                                                <div className="tb-details-col-btn">
                                                    <div className="demo-inline-spacing">
                                                        <Button color='flat-success' onClick={increaseCount}> <span className='mr-4'><PlusSquare /></span> Add New Row</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <div className="demo-inline-spacing">
                                <Button color='primary' type='submit'>
                                    {/* <Button color='primary' type='button' onClick={handleSubmit(onSubmit)}> */}
                                    Save
                                </Button>{' '}
                            </div>
                            <div className="demo-inline-spacing">
                                <Button color='secondary' onClick={() => setOpenModal(!openModal)}>
                                    Cancel
                                </Button>{' '}
                            </div>
                        </ModalFooter>
                    </Form>
                </Modals>

            </Form>

            {/* Add Terms and Condition */}
            <Form>
                <Modals openModal={openTCModal} setOpenModal={setTCOpenModal} title='Add Terms and Condition' >
                    <ModalBody>
                        <Row>
                            <Col md='12'>
                                <Label>1. 100% of subvention amount shall be passed on the DSA</Label>
                            </Col>
                            <Col md='12'>
                                <Label>2. Payout slabs are gross payouts.All payout shalle be subject To TDS deduction @ 5% u/s 194H</Label>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <div className="demo-inline-spacing">
                            <Button color='primary' type='submit'>
                                {/* <Button color='primary' type='button' onClick={handleSubmit(onSubmit)}> */}
                                Add
                            </Button>{' '}
                        </div>
                        <div className="demo-inline-spacing">
                            <Button color='secondary' onClick={() => setTCOpenModal(!openTCModal)}>
                                Cancel
                            </Button>{' '}
                        </div>
                    </ModalFooter>
                </Modals>

            </Form>
        </>
    )
}

export default PayoutGrid