import { useState } from 'react';

export function Pagination(props) {
    console.log('Pagination props: ', props);
    const { currentPage = 0, totalRecords, perPage, pageChangeHandler, Next } = props;
    const [pageNumberSelect, setPageNumberSelect] = useState(
        parseInt(currentPage)
    );
    const totalPages = Math.ceil(totalRecords / perPage);
    let previous = parseInt(pageNumberSelect) - 1;
    let next = parseInt(pageNumberSelect) + 1;
    if (previous === 0) previous = 1;
    if (next === totalPages + 1) next = totalPages;

    let pageRecords = [];

    if (totalPages > 6) {
        if (pageNumberSelect < 5) {
            pageRecords = [1, 2, 3, 4, 5, '...', totalPages];
        } else {
            if (next === totalPages - 2) {
                pageRecords = [
                    1,
                    '...',
                    previous,
                    pageNumberSelect,
                    next,
                    next + 1,
                    totalPages,
                ];
            } else if (next === totalPages - 1) {
                pageRecords = [
                    1,
                    '...',
                    previous - 1,
                    previous,
                    pageNumberSelect,
                    next,
                    totalPages,
                ];
            } else if (next === totalPages && pageNumberSelect !== totalPages) {
                pageRecords = [
                    1,
                    '...',
                    previous - 2,
                    previous - 1,
                    previous,
                    pageNumberSelect,
                    next,
                ];
            } else if (next === totalPages && pageNumberSelect === totalPages) {
                pageRecords = [
                    1,
                    '...',
                    pageNumberSelect - 4,
                    pageNumberSelect - 3,
                    pageNumberSelect - 2,
                    pageNumberSelect - 1,
                    pageNumberSelect,
                ];
            } else {
                pageRecords = [
                    1,
                    '...',
                    previous,
                    pageNumberSelect,
                    next,
                    '...',
                    totalPages,
                ];
            }
        }
    } else {
        for (let i = 1; i <= totalPages; i++) {
            pageRecords.push(i);
        }
    }
    const pageClickHandler = (e) => {
        const clickedPage = e.target.getAttribute('data');
        if (clickedPage === '...') return;

        const isPageChanged = clickedPage !== pageNumberSelect;
        setPageNumberSelect(e.target.getAttribute('data'));
        if (isPageChanged) {
            pageChangeHandler(e.target.getAttribute('data'));
        }
    };

    return (
        <div className="pagination">
            <div className="page__nav">
                <ul className="page__numbers">
                    {pageRecords.map((pageNumber) => {
                        return (
                            <li key={pageNumber} onClick={pageClickHandler}>
                                <a
                                    href="##"
                                    data={pageNumber}
                                    className={`page__number ${pageNumber === pageNumberSelect
                                        ? 'page--current'
                                        : pageNumber === '...'
                                            ? 'blank'
                                            : ''
                                        }`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                    }}
                                >
                                    {pageNumber}
                                </a>
                            </li>
                        );
                    })}
                    <li>
                        <a
                            href="##"

                            className="next-btn"
                            onClick={(e) => {
                                Next(totalPages);
                                e.preventDefault();

                            }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path fill="currentColor" d="M340.864 149.312a30.592 30.592 0 0 0 0 42.752L652.736 512L340.864 831.872a30.592 30.592 0 0 0 0 42.752a29.12 29.12 0 0 0 41.728 0L714.24 534.336a32 32 0 0 0 0-44.672L382.592 149.376a29.12 29.12 0 0 0-41.728 0z" /></svg>
                        </a>
                    </li>
                </ul>
            </div>

        </div>
    );
}
