export const multiLingColumns = [
    {
        name: 'SR.NO.',
        sortable: true,
        maxWidth: '125px',
        selector: row => row.loginCodeListId
    },
    {
        name: 'Bank/NBFC Name',
        sortable: true,
        minWidth: '200px',
        selector: row => row.bank_nbfc_name
    },
    {
        name: 'DSA Code',
        sortable: true,
        minWidth: '200px',
        selector: row => row.dsa_code
    },
    {
        name: 'DSA Name',
        sortable: true,
        minWidth: '100px',
        selector: row => row.dsa_name
    },
    {
        name: 'Product',
        sortable: true,
        minWidth: '150px',
        selector: row => row.product
    },
]
